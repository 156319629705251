import React, { useEffect, useState, useRef } from 'react';
import './styles.css';

import APIS from '../../services/api';
import { Container, Modal, Table, Button } from 'react-bootstrap';
import FloatingDiv from '../Floating';

export default function Health() {
  const intervalId = useRef(null);
  const [apiHealths, setApiHealths] = useState([]);  
  const [showModal, setShowModal] = useState(false);

  const getApiHealth = async api => {
    const start = Date.now();
    try {
      const response = await api.get('health');
      const end = Date.now();
      const ping = end - start;
      return {
        ping,
        up: response.data.status === 'up',
        status: response.status,
        lastTime: new Date(),
      };
    } catch (error) {
      const end = Date.now();
      const ping = end - start;
      return {
        ping,
        up: false,
        status: 0,
        lastTime: new Date(),
      };
    }
  };

  const updateAll = async () => {
    const apiInfo = await Promise.all(
      Object.keys(APIS).map(async (apiKey) => {
        const api = APIS[apiKey];
        const apiStatusResult = await getApiHealth(api);
        return { api: apiKey, ...apiStatusResult };
      }),
    );
    setApiHealths(apiInfo);
  };

  useEffect(() => {
    updateAll();
    intervalId.current = setInterval(updateAll, 60000);

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, []);

  return (
    <FloatingDiv >
      <Container onClick={() => setShowModal(true)}>
        <div className="string-list">
          {apiHealths.map(apiStatus => (
            <div key={apiStatus.api} className="string-item">
              <div
                title={`${apiStatus.api} - ${apiStatus.ping}ms - ${new Intl.DateTimeFormat('pt-BR', {
                  year: '2-digit',
                  month: '2-digit',
                  day: '2-digit',
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric',
                }).format(new Date(apiStatus.lastTime))}`}
                className={apiStatus.up ? "dot-green" : "dot-red"}
              ></div>
            </div>
          ))}
        </div>
      </Container>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Detalhes dos Serviços</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Serviço</th>
                <th>Status</th>
                <th>Ping</th>
                <th>Última verificação</th>
              </tr>
            </thead>
            <tbody>
              {apiHealths.map(apiStatus => (
                <tr key={apiStatus.api}>
                  <td>{apiStatus.api}</td>
                  <td>
                    <div
                      title={`${apiStatus.status}`}
                      className={apiStatus.up ? "dot-green" : "dot-red"}
                    ></div>
                  </td>
                  <td>{apiStatus.ping} ms</td>
                  <td>
                    {new Intl.DateTimeFormat('pt-BR', {
                      year: 'numeric',
                      month: 'long',
                      day: '2-digit',
                      hour: 'numeric',
                      minute: 'numeric',
                      second: 'numeric',
                    }).format(new Date(apiStatus.lastTime))}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </FloatingDiv>
  );
}
