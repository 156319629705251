import React, { useState, useEffect, useCallback } from 'react';
import { Button, Table, Form, Modal, Row, Col  } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../services/api';
import BodyPage from '../../components/Body';
import SendNotificationModal from '../../components/SendNotificationModal';
import SendUserNotificationModal from '../../components/SendUserNotificationModal';

function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [currentNotification, setCurrentNotification] = useState(null);
  const [formData, setFormData] = useState({
    key: '',
    title: '',
    body: '',
    description: '',
  });

  const fetchNotifications = useCallback(async () => {
    try {
      const response = await api.srvCadastroApi.get('/notifications');
      setNotifications(response.data.success);
      setLoading(false);
    } catch (error) {
      toast.error('Falha ao buscar notificações');
      setLoading(false);
    }
  }, []);

  const handleFormChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormSubmit = async event => {
    event.preventDefault();
    if (currentNotification) {
      try {
        await api.srvCadastroApi.put(
          `/notification/${currentNotification.notification_id}`,
          formData
        );
        toast.success('Notificação atualizada com sucesso');
      } catch (error) {
        toast.error('Falha ao atualizar notificação');
      }
    } else {
      try {
        await api.srvCadastroApi.post('/notification', formData);
        toast.success('Notificação criada com sucesso');
      } catch (error) {
        toast.error('Falha ao criar notificação');
      }
    }
    fetchNotifications();
    handleClose();
  };

  const handleDelete = async notification => {
    try {
      await api.srvCadastroApi.delete(`/notification/${notification.notification_id}`);
      toast.success('Notificação deletada com sucesso');
      fetchNotifications();
    } catch (error) {
      toast.error('Falha ao deletar notificação');
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setCurrentNotification(null);
    setFormData({
      title: '',
      body: '',
      description: '',
      key: '',
    });
  };

  const handleShow = notification => {
    setCurrentNotification(notification);
    setFormData({
      title: notification.title,
      body: notification.body,
      key: notification.key,
      description: notification.description,
    });
    setShowModal(true);
  };

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  return (
    <BodyPage>
      <>
      <h1>Notificações</h1>
      < br/>
      <Row className="justify-content-between">
        <Col xs="auto">
          <SendNotificationModal />
        </Col>

        <Col xs="auto">
          <SendUserNotificationModal />
        </Col>                

        <Col xs="auto">
            <Button
            onClick={() => setShowModal(true)}
            variant="success"
            className="mb-3"
            >
            Adicionar Notificação
            </Button>
        </Col>
      </Row>

        {loading ? (
          'Carregando...'
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Key</th>
                <th>Título</th>
                <th>Conteúdo</th>
                <th>Descrição (opcional)</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {notifications.map(notification => (
                <tr key={notification.notification_id}>
                  <td>{notification.key}</td>
                  <td>{notification.title}</td>
                  <td>{notification.body}</td>
                  <td>{notification.description}</td>
                  <td>
                    <Button
                      variant="primary"
                      size='sm'
                      onClick={() => handleShow(notification)}
                    >
                                      Editar
                    </Button>
                    
                    <Button
                      variant="danger"
                      size='sm'
                      onClick={() => handleDelete(notification)}
                    >
                      Deletar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {currentNotification ? 'Editar Notificação' : 'Adicionar Notificação'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleFormSubmit}>
            <Form.Group>
                <Form.Label>Key</Form.Label>
                <Form.Control
                  type="text"
                  name="key"
                  value={formData.key}
                  onChange={handleFormChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Título</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleFormChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Conteúdo</Form.Label>
                <Form.Control
                  type="text"
                  name="body"
                  value={formData.body}
                  onChange={handleFormChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  value={formData.description}
                  onChange={handleFormChange}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                {currentNotification ? 'Atualizar Notificação' : 'Adicionar Notificação'}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    </BodyPage>
  );
}

export default Notifications;

