import React, { useState, useEffect, useCallback } from 'react';
import { Button, Table, Form, Image, Modal, Dropdown, DropdownButton } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../services/api';
import BodyPage from '../../components/Body';
import NarrationModal from '../../components/NarrationModal';

function Reviews() {
  const [matches, setMatches] = useState([]);
  const [reporters, setReporters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [currentMatch, setCurrentMatch] = useState(null);
  const [selectedReporter, setSelectedReporter] = useState(null);
  const [reviewText, setReviewText] = useState('');
  const [matchNarrations, setMatchNarrations] = useState({});

  const [showNarrationModal, setShowNarrationModal] = useState(false);
  const [selectedMatchId, setSelectedMatchId] = useState(null);

  const fetchMatches = useCallback(async () => {
    try {
      const response = await api.bffPartidasApi.get('/matches/historic');
      setMatches(response.data.data);

      if(response.data.data) {
        const match_ids = [];
        for(let match of response.data.data) {
          match_ids.push(match.match_id);
        }

        const matchNarrationResponse = await api.srvScrapingApi.post('narration/matches', { match_ids });
        setMatchNarrations(matchNarrationResponse.data.data);
      }
      setLoading(false);
    } catch (error) {
      toast.error('Failed to fetch matches');
      setLoading(false);
    }
  }, []);

  const fetchReporters = useCallback(async () => {
    try {
      const response = await api.srvCadastroApi.get('/reporters');
      setReporters(response.data.success);
    } catch (error) {
      toast.error('Failed to fetch reporters');
    }
  }, []);

  const handleModalOpen = (match) => {
    setCurrentMatch(match);
    setShowModal(true);
    fetchReporters();
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedReporter(null);
    setReviewText('');
  };

  const handleSaveReview = async () => {
    try {
      const isEditing = currentMatch.reviewData.isReview;
      const method = isEditing ? 'put' : 'post';
      const response = await api.srvCadastroApi[method](`/review${isEditing ? `/match/${currentMatch.match_id}` : ''}`, {
        match_id: currentMatch.match_id,
        reporter_id: selectedReporter.reporter_id,
        review: reviewText,
      });
      if (response.status === 200) {
        toast.success('Review saved successfully');
        handleModalClose();
        fetchMatches();
      }
    } catch (error) {
      toast.error('Failed to save review');
    }
  };

  const handleNarrationComponent = (match) => {
    setSelectedMatchId(match.match_id);
    setShowNarrationModal(true);
  };

  const hideNarrationModal = () => {
    setShowNarrationModal(false);
    setSelectedMatchId(null);
  };

  useEffect(() => {
    fetchMatches();
  }, [fetchMatches]);

  return (
    <BodyPage>
      <h2>Partidas Finalizadas</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Partida ID</th>
                <th>Campeonato</th>
                <th>Times</th>
                <th>Data</th>
                <th>Review</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {matches && matches.map((match) => (
                <tr key={match.match_id}>
                  <td>{match.match_id}</td>
                  <td>{match.championship_name}</td>
                  <td>
                    {match.team_one_name} VS {match.team_two_name}
                  </td>
                  <td>{match.date}</td>
                  <td>
                    {match.reviewData.isReview ? (
                      <p>
                        <Image src={match.reviewData.avatar} roundedCircle width="30" height="30" className="mr-2" />
                        {match.reviewData.review}</p>
                    ) : (
                      <p> - </p>
                    )}
                  </td>
                  <td style={{display: 'flex', justifyContent: 'space-around'}}>
                    <Button variant="primary" size='sm' onClick={() => handleModalOpen(match)}>
                      {match.reviewData.isReview ? 'Editar Review' : 'Adicionar Review'}
                    </Button>

                    { matchNarrations[match.match_id] && (
                       <>
                       <Button variant="success" size='sm' onClick={() => handleNarrationComponent(match)}>
                         Narração
                       </Button>
                 
                       <NarrationModal
                         show={showNarrationModal}
                         matchId={selectedMatchId}
                         onHide={hideNarrationModal}
                       />
                     </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Modal show={showModal} onHide={handleModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>{currentMatch?.reviewData.isReview ? 'Editar Review' : 'Adicionar Review'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <DropdownButton id="dropdown-basic-button" title={selectedReporter ? selectedReporter.name : "Select a Reporter"}>
                {reporters.map((reporter) => (
                  <Dropdown.Item key={reporter.reporter_id} onClick={() => setSelectedReporter(reporter)}>
                    <Image src={reporter.avatar_url} roundedCircle width="30" height="30" className="mr-2" />
                    {reporter.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <Form.Group className="mt-3">
                <Form.Label>Review</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={reviewText}
                  onChange={(e) => setReviewText(e.target.value)}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalClose}>
                Fechar
              </Button>
              <Button variant="primary" onClick={handleSaveReview}>
                Salvar
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </BodyPage>
  );
}

export default Reviews;

             
