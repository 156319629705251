import React from 'react';
import { Card, Image } from 'react-bootstrap';
import './index.css'

function MatchCardHeader({ match }) {
  return (
    <Card.Header>
      <div className="match-details">
        <div className="match-info text-center">
          <strong>{match.championship_nickname}</strong>
          <p>
            <small>
              {match.date} - {match.hour}
            </small>
          </p>
        </div>
        <div className="match-teams d-flex align-items-center justify-content-center">
          <Image src={match.team_one_logo} width="30" height="30" />
          <span className="ml-2 mr-4">{match.team_one_name}</span>
          <span>{match.result}</span>
          <span className="ml-4 mr-2">{match.team_two_name}</span>
          <Image src={match.team_two_logo} width="30" height="30" />
        </div>
      </div>
    </Card.Header>
  );
}

export default MatchCardHeader;
