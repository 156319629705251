import React, { useState } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import LoadingIndicator from '../../components/Spinner';
import api from '../../services/api';
import { toast } from 'react-toastify';

export default function MatchWinners({ match_id, winners, active, onClose }) {
  const [loading, setLoading] = useState(false);

  const handleSave = () => {

    setLoading(true);
    api.srvCadastroApi
      .put('match')
      .then(response => {
        setLoading(false);
        toast.success('');
        onClose();
      })
      .catch(err => {
        const { error, values } = err.response.data;
        if (error && error === 'Invalid form') {
          toast.error(`Formulário inválido: ${values.join(', ')}`);
        } else {
          toast.error('');
        }
        setLoading(false);
      });
  };

  return (
    <Modal show={active} onHide={onClose}>
      {loading && <LoadingIndicator />}
      <Modal.Header closeButton>
        <Modal.Title>Vencedores</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {winners.map(win => {
          return (
            <Alert key={win.winners_id} variant={'success'}>
              <span>Tipo: </span> <strong>{win.type_choice_win}</strong> <br />
              <span>Nome: </span> <strong>{win.user.user_name}</strong><br />
              <span>UF: </span> <strong>{win.user.uf}</strong>
              <br />
              <span>Valor: </span>{' '}
              <strong>R${String(win.reward).replace('.', ',')}</strong>
              <br />
              <span>Sorteado em: </span>{' '}
              <strong>
                {new Intl.DateTimeFormat('pt-BR', {
                  year: '2-digit',
                  month: '2-digit',
                  day: '2-digit',
                  hour: 'numeric',
                  minute: 'numeric',
                }).format(new Date(win.createdAt))}
              </strong>
            </Alert>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancelar
        </Button>
        <Button variant="success" onClick={handleSave}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
