import React, { useState, useEffect } from 'react';
import { Button, Modal, Card, ListGroup, Col } from 'react-bootstrap';
import api from '../../services/api';

function NarrationModal({ matchId, show, onHide }) {
  const [narrationData, setNarrationData] = useState(null);

  useEffect(() => {
    const fetchNarration = async () => {
      const response = await api.srvScrapingApi.get(`narration/match/${matchId}`);
      setNarrationData(response.data.data);
    };

    if (show) {
      fetchNarration();
    }
  }, [matchId, show]);

  const copyNarrationToClipboard = () => {
    const text = narrationData.narration.join('\n');
    navigator.clipboard.writeText(text);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Col>
            <Modal.Title>{narrationData?.team_data.team_one_name} vs {narrationData?.team_data.team_two_name}</Modal.Title>
        </Col>
        <Col>
            <Button variant="primary" onClick={copyNarrationToClipboard}>
            Clipboard
            </Button>
        </Col>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Header>Narration</Card.Header>
          <ListGroup variant="flush">
            {narrationData?.narration.map((line, index) => (
              <ListGroup.Item key={index}>{line}</ListGroup.Item>
            ))}
          </ListGroup>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={copyNarrationToClipboard}>
          Clipboard
        </Button>
        <Button variant="secondary" onClick={onHide}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NarrationModal;
