import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, Table, Card, Row, Form } from 'react-bootstrap';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import api from '../../services/api';
import { getLocalStorageValue } from '../../util/functionsUtil';

const LogManagement = () => {
  const [logs, setLogs] = useState({ bffs: [], srvs: [], jobs: [] });
  const [services, setServices] = useState({});
  const [selectedService, setSelectedService] = useState(null);

  const [updateRate, setUpdateRate] = useState(() =>
    getLocalStorageValue('updateRate', 0)
  );
  const [logOutLines, setLogOutLines] = useState(() =>
    getLocalStorageValue('logOutLines', 50)
  );
  const [logErrorLines, setLogErrorLines] = useState(() =>
    getLocalStorageValue('logErrorLines', 30)
  );

  const updateInterval = useRef(null);

  const selectService = useCallback(
    async (service, logOutLines, logErrorLines) => {
      try {
        setSelectedService(service);
        let apiCall = null;
        if(process.env.REACT_APP_AMBIENTE === 'prod') {
          apiCall = service.startsWith('BFF-')
            ? api.srvDevopsBffApi.get
            : api.srvDevopsSrvApi.get;
        } else {
          apiCall = api.srvDevopsApi;
        }
        const response = await apiCall(
          `/logs/${service}/${logOutLines}/${logErrorLines}`
        );

        const { log, logError } = response.data.logContent;

        setServices({
          ...services,
          [service]: { logError, log },
        });
      } catch (err) {
        console.error(err);
      }
    },
    [services]
  );


  useEffect(() => {
    fetchLogs();
  }, []);

  useEffect(() => {
    if (updateRate > 0 && selectedService) {
      updateInterval.current = setInterval(
        () => selectService(selectedService, logOutLines, logErrorLines),
        updateRate * 1000
      );
      return () => clearInterval(updateInterval.current);
    }
  }, [updateRate, selectedService, logOutLines, logErrorLines, selectService]);

  const fetchLogs = async () => {

    const logGet = async apiToCall => {
      try {
        const response = await apiToCall.get('/logs');
        return response;
      } catch (error) {
        console.log(error);
        return { data: ['ERRO'] }
      }
    }
    try {
      let responseSrv = null;
      let responseBff = null;
      if(process.env.REACT_APP_AMBIENTE === 'prod') {
        responseSrv = await logGet(api.srvDevopsSrvApi);
        responseBff = await logGet(api.srvDevopsBffApi);
      } else {
        const response = await logGet(api.srvDevopsApi);
        responseSrv = response;
        responseBff = response;
      }
      const allServices = {
        bffs: responseBff.data,
        srvs: responseSrv.data.filter(srv => !srv.toLowerCase().startsWith('job')),
        jobs: responseSrv.data.filter(srv => srv.toLowerCase().startsWith('job')),
      };
      setLogs(allServices);
      const cacheLog = {};
      for (let context in allServices) {
        for (let ms of allServices[context]) {
          cacheLog[ms] = { log: '', logError: '' };
        }
      }
      setServices(cacheLog);
      return;
    } catch (err) {
      console.error(err);
    }
  };

  const handleUpdateRateChange = e => {
    const newValue = Number(e.target.value);
    setUpdateRate(newValue);
    localStorage.setItem('updateRate', JSON.stringify(newValue));
  };

  const handleLogOutLinesChange = e => {
    const newValue = Number(e.target.value);
    setLogOutLines(newValue);
    localStorage.setItem('logOutLines', JSON.stringify(newValue));
  };

  const handleLogErrorLinesChange = e => {
    const newValue = Number(e.target.value);
    setLogErrorLines(newValue);
    localStorage.setItem('logErrorLines', JSON.stringify(newValue));
  };

  const renderTable = (title, data, color) => (
    <Table striped bordered hover style={{ borderColor: color }}>
      <thead style={{ backgroundColor: color, color: 'white' }}>
        <tr>
          <th colSpan="2">{title}</th>
        </tr>
        <tr>
          <th>Arquivo de Log</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        {data.map(log => (
          <tr key={log}>
            <td>{log}</td>
            <td>
              <Button
                variant="primary"
                onClick={() => selectService(log, logOutLines, logErrorLines)}
              >
                Visualizar
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  return (
    <div>
      <br />
      <br />
      <br />
      <h2>Gestão de Logs</h2>

      <br />
      <Row className="bg-light p-3">
        <Form className="d-flex justify-content-between">
          <Form.Group controlId="updateRate" className="mr-2">
            <Form.Label>
              <strong>Atualização (s):</strong>
            </Form.Label>
            <Form.Control
              type="number"
              placeholder=""
              value={updateRate}
              onChange={handleUpdateRateChange}
              min={0}
              max={100}
              step={1}
              style={{ width: '100px' }}
            />
          </Form.Group>

          <Form.Group controlId="logOut" className="mr-2">
            <Form.Label>
              <strong>Linhas - out</strong>
            </Form.Label>
            <Form.Control
              type="number"
              placeholder=""
              value={logOutLines}
              onChange={handleLogOutLinesChange}
              min={0}
              max={1000}
              step={1}
              style={{ width: '100px' }}
            />
          </Form.Group>

          <Form.Group controlId="logError">
            <Form.Label>
              <strong>Linhas - error</strong>
            </Form.Label>
            <Form.Control
              type="number"
              placeholder=""
              value={logErrorLines}
              onChange={handleLogErrorLinesChange}
              min={0}
              max={1000}
              step={1}
              style={{ width: '100px' }}
            />
          </Form.Group>
        </Form>
      </Row>

      {selectedService && services[selectedService] && (
        <div>
          <h3>{selectedService}</h3>
          <Card bg="light">
            <Card.Header>Log de Sucesso</Card.Header>
            <Card.Body>
              <SyntaxHighlighter language="javascript" style={solarizedlight}>
                {services[selectedService].log || 'Nenhum log de sucesso.'}
              </SyntaxHighlighter>
            </Card.Body>
          </Card>
          <Card bg="danger" text="white">
            <Card.Header>Log de Erro</Card.Header>
            <Card.Body>
              <SyntaxHighlighter language="javascript" style={solarizedlight}>
                {services[selectedService].logError || 'Nenhum log de erro.'}
              </SyntaxHighlighter>
            </Card.Body>
          </Card>
        </div>
      )}
      {renderTable('BFFs', logs.bffs, '#c8a2c8')}
      {renderTable('SRVs', logs.srvs, '#4b0082')}
      {renderTable('Jobs', logs.jobs, '#dd4814')}
    </div>
  );
};

export default LogManagement;
