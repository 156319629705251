import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { MatchCardChart } from '../../components/MatchCardChart';
import BodyPage from '../../components/Body';

export function ChartMatchPage() {
  const [matchesData, setMatchesData] = useState([]);
  const [statisticsData, setStatisticsData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const matchesResponse = await api.bffPartidasApi.get('matches');
        const statisticsResponse = await api.jobVencedoresApi.post('statistics/guess-match', { fullData: true });

        if (matchesResponse.data.success && statisticsResponse.data.success) {
          setMatchesData(matchesResponse.data.success.matches);
          setStatisticsData(statisticsResponse.data.success);
        }
      } catch (error) {
        console.error('Erro ao buscar os dados:', error);
      }
    }

    fetchData();
  }, []);

  const mergeData = (match) => {
    const stats = statisticsData.find(stat => stat.match_id === match.match_id);
    return { match, statistics: stats };
  };

  return (
    <BodyPage>
        <h2>Partidas Ativas</h2>
        <br />
        <div className="matches-page">
            <br />
            {matchesData.map(match => (
                <MatchCardChart key={match.match_id} {...mergeData(match)} />
            ))}
        </div>  
    </BodyPage>
  );
}

export default ChartMatchPage;
