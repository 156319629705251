import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import Dashboard from '../pages/Dashboard';
import ChampsAll from '../pages/ChampsAll';
import MatchAll from '../pages/MatchAll';
import MatchCreate from '../pages/MatchCreate';
import MatchUpdate from '../pages/MatchUpdate';
import Public from '../pages/Public';
import Teams from '../pages/Teams';
import TypeMatchConfig from '../pages/TypeMatchConfig';
import LogManagement from '../pages/LogManagement';
import Reporters from '../pages/Reporters';
import Reviews from '../pages/Reviews';
import Notifications from '../pages/Notifications';
import Users from '../pages/Users';
import Parametrizadors from '../pages/Parametrizador';
import WinnersManagement from '../pages/WinnersManagement';
import ChartMatchPage from '../pages/ChartMatchPage';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Public} />
      <Route path="/login" exact component={SignIn} />

      <Route path="/inicio" exact component={Dashboard} isPrivate/>
      <Route path="/campeonatos" exact component={ChampsAll} isPrivate />
      <Route path="/partidas" exact component={MatchAll} isPrivate />
      <Route path="/partidas/criar" exact component={MatchCreate} isPrivate />
      <Route path="/partidas/atualizar" exact component={MatchUpdate} isPrivate />
      <Route path="/partidas/tipo-configucacao-partida" exact component={TypeMatchConfig} isPrivate />
      <Route path="/partidas/vencedores" exact component={WinnersManagement} isPrivate />      
      <Route path="/partidas/graficos" exact component={ChartMatchPage} isPrivate />      
      <Route path="/times" exact component={Teams} isPrivate />
      <Route path="/reporters" exact component={Reporters} isPrivate />
      <Route path="/reviews" exact component={Reviews} isPrivate />
      <Route path="/notifications" exact component={Notifications} isPrivate />
      <Route path="/usuarios" exact component={Users} isPrivate />
      <Route path="/parametrizador" exact component={Parametrizadors} isPrivate />
      <Route path="/logs" exact component={LogManagement} isPrivate />

      <Route path="/" component={() => <h1>404</h1>} />
    </Switch>
  );
}
