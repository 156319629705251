
// Status de pagamento para vencedores
export const TO_PAY = 1; // Aguardando pagamento
export const PENDENCIES = 2; // Alguma pendência
export const WILL_NOT_PAT = 3; // Não será pago
export const PAID = 4; // Pago

export function getPaymentWinStatusDescription(paymentWinStatus) {
    switch (paymentWinStatus) {
        case 1:
            return '🟡 Aguardando pagamento';
        case 2:
            return '🟠 Pendências';
        case 3:
            return '☑️ Descartado';
        case 4:
            return '✔️ Pagamento finalizado';        
        default:
            return 'Erro'    
    }
}