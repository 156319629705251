import React, { useEffect, useState } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Pagination,
  InputGroup,
  FormControl,
  Row,
  Col,
} from 'react-bootstrap';

import {
  FaSearch,
  FaPencilAlt,
  FaCheck,
  FaTimes,
  FaChartBar,
  FaUsers,
  FaCheckCircle,
  FaTimesCircle,
  FaPercentage,
} from 'react-icons/fa';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import api from '../../services/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import BodyPage from '../../components/Body';

function Users() {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [editingUser, setEditingUser] = useState(null);
  const [editValues, setEditValues] = useState({
    user_name: '',
    user_last_name: '',
    email: '',
  });

  const [showGraph, setShowGraph] = useState(false);
  const [generalStats, setGeneralStats] = useState({});
  const [dailyStats, setDailyStats] = useState([]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Limpe o event listener quando o componente for desmontado
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const chartWidth = windowWidth * 0.83; // percentual de largura do gráfico de usuários

  useEffect(() => {
    fetchGeneralStats();
    fetchDailyStats();
  }, [showGraph]);

  useEffect(() => {
    fetchUsers();
  }, [page, searchTerm]);

  const fetchUsers = async () => {
    try {
      const response = await api.srvCadastroApi.get(
        `/users/search?searchTerm=${searchTerm}&page=${page}&limit=${limit}`
      );
      const { success } = response.data;
      if (success) setUsers(success);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchGeneralStats = async () => {
    try {
      const response = await api.srvCadastroApi.get(
        '/users/statistics/general'
      );
      if (response.data.success) setGeneralStats(response.data.success);
    } catch (error) {
      console.error('Error fetching general statistics:', error);
    }
  };

  const fetchDailyStats = async () => {
    try {
      const response = await api.srvCadastroApi.get(
        '/users/statistics/count-by-day'
      );
      if (response.data.success) {
        // Logic to fill days with 0 user creation
        // (left as a challenge as it requires a date manipulation library or manual logic)
        setDailyStats(response.data.success);
      }
    } catch (error) {
      console.error('Error fetching daily statistics:', error);
    }
  };

  const handleSearchChange = e => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = selectedPage => {
    setPage(selectedPage);
  };

  const handleEditUser = user => {
    setEditingUser(user);
    setEditValues({
      user_name: user.user_name,
      user_last_name: user.user_last_name,
      email: user.email,
    });
  };

  const handleEditChange = e => {
    setEditValues({ ...editValues, [e.target.name]: e.target.value });
  };

  const updateUser = async () => {
    try {
      await api.srvCadastroApi.put(`/user/${editingUser.user_id}`, editValues);
      setEditingUser(null);
      fetchUsers();
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const renderUsers = () => {
    return users.map(user => (
      <tr key={user.user_id}>
        <td>{user.uf}</td>
        <td>{user.user_name}</td>
        <td>{user.user_last_name}</td>
        <td>{user.email}</td>
        <td>{user.cpf_hash ? <FaCheck /> : <FaTimes />}</td>
        <td>{user.pix_key_hash ? <FaCheck /> : <FaTimes />}</td>
        <td>{user.cellphone_hash ? <FaCheck /> : <FaTimes />}</td>
        <td>{user.password_hash ? <FaCheck /> : <FaTimes />}</td>
        <td>{user.firebase_id ? <FaCheck /> : <FaTimes />}</td>
        <td>
          <Button
            variant="outline-primary"
            onClick={() => handleEditUser(user)}
          >
            <FaPencilAlt />
          </Button>
        </td>
      </tr>
    ));
  };

  return (
    <BodyPage>
      <div>
        <h2>Usuários</h2>

        <br />

        <Row>
          <Col className="text-center d-flex flex-column align-items-center">
            <FaUsers size={30} className="mb-2" />
            <strong>Total de Usuários:</strong> {generalStats.totalUsers}
          </Col>
          <Col className="text-center d-flex flex-column align-items-center">
            <FaCheckCircle size={30} className="mb-2" color="green" />
            <strong>Compliance:</strong> {generalStats.compliance}
          </Col>
          <Col className="text-center d-flex flex-column align-items-center">
            <FaTimesCircle size={30} className="mb-2" color="red" />
            <strong>Non-Compliance:</strong> {generalStats.nonCompliance}
          </Col>
          <Col className="text-center d-flex flex-column align-items-center">
            <FaPercentage size={30} className="mb-2" />
            <strong>Compliance %:</strong> {generalStats.compliancePercentage}%
          </Col>
          <Col className="text-center d-flex flex-column align-items-center">
            <FaPercentage size={30} className="mb-2" />
            <strong>Non-Compliance %:</strong>{' '}
            {generalStats.nonCompliancePercentage}%
          </Col>
        </Row>

        <br />
        
        <Row className="mb-4">
          <Col>
            <Button
              variant="success"
              size="lg"
              onClick={() => setShowGraph(!showGraph)}
              className="d-flex align-items-center justify-content-center"
            >
              <FaChartBar size={20} className="mr-2" />
            </Button>
          </Col>
        </Row>

        {showGraph && (
          <BarChart width={chartWidth} height={250} data={dailyStats}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="day"
              tickFormatter={dateStr => {
                const [year, month, day] = dateStr.split('T')[0].split('-');
                return `${day}/${month}/${year}`;
              }}
            />
            <YAxis />
            <Tooltip
              labelFormatter={dateStr => {
                const [year, month, day] = dateStr.split('T')[0].split('-');
                return `${day}/${month}/${year}`;
              }}
            />
            <Bar dataKey="userCount" fill="#8884d8" />
          </BarChart>
        )}

        <InputGroup className="mb-3">
          <FormControl
            placeholder="Pesquisar..."
            aria-label="Pesquisar..."
            aria-describedby="basic-addon2"
            onChange={handleSearchChange}
          />
          <InputGroup.Append>
            <Button variant="outline-secondary">
              <FaSearch />
            </Button>
          </InputGroup.Append>
        </InputGroup>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>UF</th>
              <th>Nome</th>
              <th>Sobrenome</th>
              <th>Email</th>
              <th>CPF</th>
              <th>Pix Key</th>
              <th>Cellphone</th>
              <th>Password</th>
              <th>Firebase ID</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{renderUsers()}</tbody>
        </Table>
        <Pagination>
          <Pagination.Prev
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
          />
          <Pagination.Item active>{page}</Pagination.Item>
          <Pagination.Next onClick={() => handlePageChange(page + 1)} />
        </Pagination>

        <Modal show={editingUser !== null} onHide={() => setEditingUser(null)}>
          <Modal.Header closeButton>
            <Modal.Title>Editar Usuário</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type="text"
                  name="user_name"
                  value={editValues.user_name}
                  onChange={handleEditChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Sobrenome</Form.Label>
                <Form.Control
                  type="text"
                  name="user_last_name"
                  value={editValues.user_last_name}
                  onChange={handleEditChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={editValues.email}
                  onChange={handleEditChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setEditingUser(null)}>
              Fechar
            </Button>
            <Button variant="primary" onClick={updateUser}>
              Salvar Alterações
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </BodyPage>
  );
}

export default Users;
