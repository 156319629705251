import React, { useState, useEffect, useCallback } from 'react';
import { Button, Table, Form, Image, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../services/api';
import BodyPage from '../../components/Body';

function Reporters() {
  const [reporters, setReporters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [currentReporter, setCurrentReporter] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    personality: '',
    avatar_url: '',
  });

  const fetchReporters = useCallback(async () => {
    try {
      const response = await api.srvCadastroApi.get('/reporters');
      setReporters(response.data.success);
      setLoading(false);
    } catch (error) {
      toast.error('Failed to fetch reporters');
      setLoading(false);
    }
  }, []);

  const handleFormChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormSubmit = async event => {
    event.preventDefault();
    if (currentReporter) {
      try {
        await api.srvCadastroApi.put(
          `/reporter/${currentReporter.reporter_id}`,
          formData
        );
        toast.success('Reporter updated successfully');
      } catch (error) {
        toast.error('Failed to update reporter');
      }
    } else {
      try {
        await api.srvCadastroApi.post('/reporter', formData);
        toast.success('Reporter created successfully');
      } catch (error) {
        toast.error('Failed to create reporter');
      }
    }
    fetchReporters();
    handleClose();
  };

  const handleDelete = async reporter => {
    try {
      await api.srvCadastroApi.delete(`/reporter/${reporter.reporter_id}`);
      toast.success('Reporter deleted successfully');
      fetchReporters();
    } catch (error) {
      toast.error('Failed to delete reporter');
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setCurrentReporter(null);
    setFormData({
      name: '',
      personality: '',
      avatar_url: '',
    });
  };

  const handleShow = reporter => {
    setCurrentReporter(reporter);
    setFormData({
      name: reporter.name,
      personality: reporter.personality,
      avatar_url: reporter.avatar_url,
    });
    setShowModal(true);
  };

  useEffect(() => {
    fetchReporters();
  }, [fetchReporters]);

  return (
    <BodyPage>
      <>
        <Button
          onClick={() => setShowModal(true)}
          variant="success"
          className="mb-3"
        >
          Adicionar Reporter
        </Button>
        {loading ? (
          'Loading...'
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Personalidade</th>
                <th>Avatar</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {reporters.map(reporter => (
                <tr key={reporter.reporter_id}>
                  <td>{reporter.name}</td>
                  <td>{reporter.personality}</td>
                  <td>
                    <Image
                      src={reporter.avatar_url}
                      alt={reporter.name}
                      width="50"
                      height="50"
                    />
                  </td>

                  <td>
                    <Button
                      variant="primary"
                      size='sm'
                      onClick={() => handleShow(reporter)}
                    >
                      Editar
                    </Button>
                    
                    <Button
                      variant="danger"
                      size='sm'
                      onClick={() => handleDelete(reporter)}
                    >
                      Deletar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {currentReporter ? 'Edit Reporter' : 'Add Reporter'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleFormSubmit}>
              <Form.Group>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleFormChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Personalidade</Form.Label>
                <Form.Control
                  type="text"
                  name="personality"
                  value={formData.personality}
                  onChange={handleFormChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Avatar URL</Form.Label>
                <Form.Control
                  type="text"
                  name="avatar_url"
                  value={formData.avatar_url}
                  onChange={handleFormChange}
                  required
                />
                {formData.avatar_url.endsWith('.png') && (
                  <Image
                    src={formData.avatar_url}
                    alt="avatar"
                    fluid
                    className="my-3"
                  />
                )}
              </Form.Group>
              <Button variant="primary" type="submit">
                {currentReporter ? 'Atualizar Reporter' : 'Adicionar Reporter'}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    </BodyPage>
  );
}

export default Reporters;
