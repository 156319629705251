import React, { useEffect, useCallback, useState } from 'react';

import { FaPlus, FaRegFutbol, FaToggleOff, FaToggleOn } from 'react-icons/fa';
import { AiFillAlert } from 'react-icons/ai';
import { Table, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import BodyPage from '../../components/Body';
import LoadingIndicator from '../../components/Spinner';
import api from '../../services/api';
import { Menu } from './styles';

export default function ChampsAll() {
  const [loading, setLoading] = useState(false);
  const [championships, setChampionships] = useState(false);

  const getAllChamps = useCallback(async () => {
    try {
      const response = await api.srvCadastroApi.get('championship');

      const { err, success } = response.data;

      if (err) {
        toast.error(err);
        return;
      }

      if (success) {
        setChampionships(success);
      }
    } catch (error) {
      toast.error('Não foi possível obter o histórico');
    }
  }, []);

  /**
   * Habilita ou desabilita um campeonato
   */
  const changeChampsStatus = useCallback(async (championship_id) => {
    try {
      const response = await api.srvCadastroApi.put(`championship/status/${championship_id}`);

      const { err, success } = response.data;

      if (err) {
        toast.error(err);
        return;
      }

      if (success) {
        getAllChamps();
      }
    } catch (error) {
      toast.error('Não foi possível obter o histórico');
    }
  }, [getAllChamps]);

  /**
   * Verifica se partida ainda não existe
   * Retorna true caso realmente não exista
   */

  const verifyIfIsNewMatch = useCallback(async (match_id) => {

    try {
      const matchExists = await api.srvCadastroApi.get(`match/${match_id}`);
      if(matchExists.data.data) {
        // Partida já existe, retorna false
        return false;
      }
    } catch (errorResp) {
      try {
        const { error } = errorResp.response.data;
        if(error && error === 'Match not found') {
          return true;
        }
      } catch (error) {
        toast.error(`Erro não esperado ao buscar partida`);
        return false;
      }
    }
  }, []);

  /**
   * Função para Criar partidas em Lote. Busca partidas da rodada seguinte e grava uma por uma que ainda não existir
   */
  const createMatchesFromNextRound = useCallback(async (championship_id, actual_round) => {
    try {
      if(!actual_round) {
        toast.error('Rodada não identificada');
        return;
      }
      // Solicite o número da rodada ao usuário
      const defaultRound = Number(actual_round) + 1;
      const userRoundInput = window.prompt("Informe o número da rodada:", defaultRound);

      if (!userRoundInput) {
        // O usuário clicou em cancelar ou deixou o valor vazio
        toast.error('Nenhuma rodada foi especificada.');
        return;
      }

      const round_id = Number(userRoundInput);
      if (isNaN(round_id) || round_id <= 0) {
        // O valor inserido não é um número válido
        toast.error('Rodada informada não é válida.');
        return;
      }
      const response = await api.srvConectorApi.get(`matches/new/${championship_id}/${round_id}`);

      const { newMatches } = response.data;

      toast.info(`Iniciando cadastro. Quantidade de partidas: ${newMatches.length}`);

      const createdMatches = [];
      const nonCreatedMatches = [];

      for (let match of newMatches) {
        // Verifica se partida já existe:
        try {
          const isNewMatch = await verifyIfIsNewMatch(match.match_id);// api.srvCadastroApi.get(`match/${match.match_id}`);
          
          if(isNewMatch) {
            // Partida não existe

            const matchCreated = await api.srvCadastroApi.post('match', match);
            if(matchCreated.data.success) {
              createdMatches.push(match);
            } else {
              nonCreatedMatches.push(match)
            }
          } else {
            nonCreatedMatches.push(match)
          }
        } catch (error) {
          // Erro ao encontrar
          console.log(error.response)
          toast.error('Erro geral ao cadastrar partida');
        }
      }

      toast.success(`Novas partidas criadas: ${createdMatches.length}`);
      toast.info(`Partidas ignoradas: ${nonCreatedMatches.length}`);
      getAllChamps();
     
    } catch (error) {
      toast.error('Erro geral ao cadastrar partidas');
    }
  }, [getAllChamps]);

  /**
   * Função para atualizar resultado de partidas da rodada atual em Lote
   */
  const getFinishedResultMatches = useCallback(async (championship_id, actual_round) => {
    try {
      if(!actual_round) {
        toast.error('Rodada não identificada');
        return;
      }
      const round_id = Number(actual_round);
      const response = await api.srvConectorApi.get(`matches/finish/${championship_id}/${round_id}`);

      const { finishedMatches } = response.data;

      toast.info(`Iniciando processo... Quantidade de partidas: ${finishedMatches.length}`);

      const createdMatches = [];
      const nonCreatedMatches = [];

      for (let match of finishedMatches) {

        try {
          const matchExists = await api.srvCadastroApi.put(`match/finish`, match);

          if(matchExists.data.message === 'Ok') {
            createdMatches.push(match);
          } else {
            nonCreatedMatches.push(match)
          }
        } catch (error) {
          // Erro ao encontrar
          toast.error('Erro geral ao finalizar partida ' + match.match_id);
        }
      }

      toast.success(`Partidas finalizadas: ${createdMatches.length}`);
      toast.info(`Partidas ignoradas: ${nonCreatedMatches.length}`);
      getAllChamps();
     
    } catch (error) {
      toast.error('Erro geral ao finalizar partidas');
    }
  }, [getAllChamps]);

  useEffect(() => {
    if (!championships) {
      getAllChamps();
    }
  }, [getAllChamps, championships]);

  /**
   * Função para atualizar lista de campeonatos, assim como suas rodadas
   * @param {*} event 
   */
  const handleUpdateChampsDatabase = event => {
    event.preventDefault();
    setLoading(true);
    api.srvConectorApi
      .post('championship/all')
      .then(() => {
        setLoading(false);
        toast.success('Banco de dados de campeonatos atualizado com sucesso');
        getAllChamps();
      })
      .catch(err => {
        setLoading(false);
        const {error} = err.response.data;
        toast.error(error);        
      });
  };

  return (
    <BodyPage>
      <caption>
        <h3>Campeonatos</h3>
      </caption>
      <Menu>
        <Button onClick={handleUpdateChampsDatabase} variant='info'>Atualizar Banco de Dados</Button>
        {loading && <LoadingIndicator />}
      </Menu>
      <Table responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>Nome</th>
            <th>Nome Popular</th>
            <th>Logo Full?</th>
            <th>Rodada</th>
            <th>Tipo</th>
            <th>Criado em</th>
            <th>Atualizado em</th>
            <th>Status</th>
            <th>Add Partidas</th>
            <th>Finalizar Partidas</th>
          </tr>
        </thead>
        <tbody>
          {championships &&
            championships.map(x => {
              return (
                <tr>
                  <td>{x.championship_id}</td>
                  <td>{x.championship_name}</td>
                  <td>{x.championship_nickname}</td>
                  <td>{x.logo_full ? 'true' : 'false'}</td>
                  <td>{x.current_round}</td>
                  <td>{x.type}</td>
                 
                  <td>
                    {new Intl.DateTimeFormat('pt-BR', {
                      year: '2-digit',
                      month: '2-digit',
                      day: '2-digit',
                      hour: 'numeric',
                      minute: 'numeric',
                    }).format(new Date(x.createdAt))}
                  </td>
                  <td>
                    {new Intl.DateTimeFormat('pt-BR', {
                      year: '2-digit',
                      month: '2-digit',
                      day: '2-digit',
                      hour: 'numeric',
                      minute: 'numeric',
                    }).format(new Date(x.updatedAt))}
                  </td>
                  <td>
                  <Button
                    onClick={() => changeChampsStatus(x.championship_id)}
                    variant={x.status ? 'success' : 'danger'}
                  >
                    {x.status ? (
                      <FaToggleOn />
                    ) : (
                      <FaToggleOff />
                    )}
                  </Button>
                  </td>
                  <td>
                    <Button
                      disabled={!x.status}
                      onClick={() => createMatchesFromNextRound(x.championship_id, x.current_round)}
                      variant={x.status ? 'success' : 'danger'}
                    >
                      {x.status ? (
                        <>
                          <FaRegFutbol /> <FaPlus /> 
                        </>
                      ) : (
                        <>
                          <FaRegFutbol /> <FaPlus /> 
                        </>
                      )}
                    </Button>
                  </td>

                  <td>
                    <Button
                      disabled={!x.status}
                      onClick={() => getFinishedResultMatches(x.championship_id, x.current_round)}
                      variant={x.status ? 'success' : 'danger'}
                    >
                      {x.status ? (
                        <>
                          <FaRegFutbol /> <AiFillAlert /> 
                        </>
                      ) : (
                        <>
                          <FaRegFutbol /> <AiFillAlert /> 
                        </>
                      )}
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </BodyPage>
  );
}
