import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Form, Button, Alert } from 'react-bootstrap';
import LoadingIndicator from '../../components/Spinner';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { getMatchConfigRequest } from './matchConfigRequest';

export default function MatchConfig({ match, active, onClose }) {
  const [loading, setLoading] = useState(false);
  const [typeChoiceWin, setTypeChoiceWin] = useState([]);
  const [configs, setConfigs] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const [formData, setFormData] = useState({
    match_id_fk: match.match_id,
    reward: '',
    minimum_guess: '',
    type_choice_win: '',
  });

  useEffect(() => {
    api.srvCadastroApi
      .get('type-match-config')
      .then(response => {
        const configs = response.data.success.map(x => x.type_choice_win);
        setTypeChoiceWin(configs)
      })
      .catch(error => console.log(error));

    api.srvCadastroApi
      .get(`match-config/match/${match.match_id}`)
      .then(response => setConfigs(response.data.success))
      .catch(error => console.log(error));
  }, [match.match_id]);

  const handleSave = (event) => {
    event.preventDefault();
    const matchConfigRequest = getMatchConfigRequest(formData);
    setLoading(true);
    api.srvCadastroApi
      .post('match-config', matchConfigRequest)
      .then(response => {
        setLoading(false);
        toast.success('Premiação de Partida cadastrada com sucesso');
        onClose();
      })
      .catch(err => {
        const { error, values } = err.response.data;
        if (error && error === 'Invalid form') {
          toast.error(`Formulário inválido: ${values.join(', ')}`);
        } else {
          toast.error(error);
        }
        setLoading(false);
      });
  };

  const handleTypeChoiceChange = event => {
    const typeChoice = event.target.value;
    setFormData(prevFormData => ({
      ...prevFormData,
      type_choice_win: typeChoice,
    }));
  };

  const handleRewardChange = event => {
    const reward = event.target.value;
    setFormData(prevFormData => ({ ...prevFormData, reward }));
  };

  const handleMinimumGuessChange = event => {
    const minimumGuess = event.target.value;
    setFormData(prevFormData => ({
      ...prevFormData,
      minimum_guess: minimumGuess,
    }));
  };

  const handleEditClick = match_config_id => {
    setIsEditing(match_config_id);
  };

  const changeConfig = useCallback(
    (key, newVal, match_config_id) => {
      if (configs) {
        const newConfigs = [];
        for (let conf of configs) {
          console.log(conf.match_config_id + ' ' + match_config_id);
          if (conf.match_config_id === match_config_id) {
            conf[key] = newVal;
          }
          newConfigs.push(conf);
        }
        setConfigs(newConfigs);
      }
    },
    [configs]
  );

  const handleSaveEdit = match_config_id => {
    const matchConfigUpdate = configs.find(
      x => x.match_config_id === match_config_id
    );
    setLoading(true);
    api.srvCadastroApi
      .put('match-config', matchConfigUpdate)
      .then(response => {
        setLoading(false);
        toast.success('Premiação de Partida atualizada com sucesso');

        api.srvCadastroApi
          .get(`match-config/match/${match.match_id}`)
          .then(response => setConfigs(response.data.success))
          .catch(error => console.log(error));
      })
      .catch(err => {
        const { error, values } = err.response.data;
        if (error && error === 'Invalid form') {
          toast.error(`Formulário inválido: ${values.join(', ')}`);
        } else {
          toast.error(error);
        }
        setLoading(false);
      });
    setIsEditing(false);
  };

  const handleDeleteEdit = match_config_id => {

    const confirmDelete = window.confirm("Tem certeza de que deseja excluir esta configuração de partida?");
  
    if (confirmDelete) {
      setLoading(true);
      api.srvCadastroApi
        .delete(`match-config/${match_config_id}`)
        .then(response => {
          setLoading(false);
          toast.success('Premiação de Partida excluída com sucesso');
  
          api.srvCadastroApi
            .get(`match-config/match/${match.match_id}`)
            .then(response => setConfigs(response.data.success))
            .catch(error => console.log(error));
        })
        .catch(err => {
          const { error } = err.response.data;
          toast.error(error);
  
          setLoading(false);
        });
      setIsEditing(false);
    }
  };
  

  return (
    <Modal show={active} onHide={onClose}>
      {loading && <LoadingIndicator />}
      <Modal.Header closeButton>
        <Modal.Title>Cadastrar Premiação</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Form>
            <Form.Group controlId="formTypeChoiceWin">
              <Form.Label>Tipo</Form.Label>
              <Form.Control
                as="select"
                size="sm"
                onChange={handleTypeChoiceChange}
                value={formData.type_choice_win}
              >
                <option value="">Selecione um tipo</option>
                {typeChoiceWin.map(typeChoice => (
                  <option key={typeChoice} value={typeChoice}>
                    {typeChoice}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="reward">
              <Form.Label>Recompensa</Form.Label>
              <Form.Control
                type="number"
                size="sm"
                min="0"
                value={formData.reward}
                onChange={event => handleRewardChange(event)}
              />
            </Form.Group>

            <Form.Group controlId="minimumGuess">
              <Form.Label>Mínimo de palpites</Form.Label>
              <Form.Control
                type="number"
                size="sm"
                min="0"
                value={formData.minimum_guess}
                onChange={event => handleMinimumGuessChange(event)}
              />
            </Form.Group>

            <Button variant="success" onClick={handleSave}>
              Adicionar
            </Button>
          </Form>
        </div>

        <div>
          <br />
          {configs &&
            configs.map(config => {
              return (
                <Alert key={config.match_config_id} variant={'success'}>
                  <span>Tipo: </span> <strong>{config.type_choice_win}</strong>{' '}
                  <br />
                  {isEditing && isEditing === config.match_config_id ? (
                    <>
                      <Form.Group controlId="reward">
                        <Form.Label>Recompensa</Form.Label>
                        <Form.Control
                          type="number"
                          size="sm"
                          min="0"
                          value={config.reward}
                          onChange={event =>
                            changeConfig(
                              'reward',
                              event.target.value,
                              config.match_config_id
                            )
                          }
                        />
                      </Form.Group>

                      <Form.Group controlId="minimumGuess">
                        <Form.Label>Mínimo de palpites</Form.Label>
                        <Form.Control
                          type="number"
                          size="sm"
                          min="0"
                          value={config.minimum_guess}
                          onChange={event =>
                            changeConfig(
                              'minimum_guess',
                              event.target.value,
                              config.match_config_id
                            )
                          }
                        />
                      </Form.Group>
                    </>
                  ) : (
                    <>
                      <span>Valor: </span>{' '}
                      <strong>
                        R${String(config.reward).replace('.', ',')}
                      </strong>
                      <br />
                      <span>Palpites mínimos: </span>{' '}
                      <strong>{config.minimum_guess}</strong>
                    </>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      alignContent: 'end',
                      justifyContent: 'space-between',
                    }}
                  >
                    {isEditing && isEditing === config.match_config_id ? (
                      <Button
                        variant="success"
                        size="sm"
                        onClick={() => handleSaveEdit(config.match_config_id)}
                      >
                        Salvar
                      </Button>
                    ) : (
                      <Button
                        variant="info"
                        size="sm"
                        onClick={() => handleEditClick(config.match_config_id)}
                      >
                        Editar
                      </Button>
                    )}

                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleDeleteEdit(config.match_config_id)}
                      >
                        Excluir
                      </Button>
                  </div>
                </Alert>
              );
            })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
