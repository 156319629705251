import React from 'react';
import { Container } from 'react-bootstrap';

export default function BodyPage({ children }) {
  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div
        style={{
          margin: 'auto',
          background: '#fff',
          height: '95%',
          width: '98%',
          padding: '24px',
          borderRadius: '5px',
          position: 'relative',
        }}
      >
        <Container fluid>{children}</Container>
      </div>
    </>
  );
}
