import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../services/api';

function SendNotificationModal() {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    body: '',
  });

  const handleFormChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormSubmit = async event => {
    event.preventDefault();
    try {
      await api.srvCadastroApi.post('/notification/send', formData);
      toast.success('Notificação enviada com sucesso');
    } catch (error) {
      toast.error('Falha ao enviar notificação');
    }
    handleClose();
  };

  const handleClose = () => {
    setShowModal(false);
    setFormData({
      title: '',
      body: '',
    });
  };

  const handleShow = () => {
    setShowModal(true);
  };

  return (
    <>
      <Button variant="danger" onClick={handleShow} className="mb-3">
        Enviar Notificação para todos
      </Button>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enviar Notificação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group>
              <Form.Label>Título</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={formData.title}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Conteúdo</Form.Label>
              <Form.Control
                type="text"
                name="body"
                value={formData.body}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Enviar Notificação
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SendNotificationModal;
