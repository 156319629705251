import React, { useState, useRef } from 'react';

const FloatingDiv = ({children}) => {
  const [position, setPosition] = useState({ x: 0, y: 50 });
  const [size, setSize] = useState({ width: 52, height: 330 });
  const ref = useRef(null);
  const resizeHandleSize = 16;
  const resizeHandleMargin = 8;

  const handleMouseDown = (e) => {
    e.preventDefault();
    const rect = ref.current.getBoundingClientRect();
    const { clientX, clientY } = e;
    if (
      clientX > rect.right - resizeHandleSize - resizeHandleMargin &&
      clientY > rect.bottom - resizeHandleSize - resizeHandleMargin
    ) {
      document.addEventListener('mousemove', handleResize);
    } else {
      document.addEventListener('mousemove', handleMouseMove);
    }
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const element = ref.current;
    const rect = element.getBoundingClientRect();
    const x = e.clientX - rect.width / 2;
    const y = e.clientY - rect.height / 2;
    setPosition({ x, y });
  };

  const handleResize = (e) => {
    const element = ref.current;
    const rect = element.getBoundingClientRect();
    const newWidth = e.clientX - rect.left;
    const newHeight = e.clientY - rect.top;
    setSize({ width: newWidth, height: newHeight });
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mousemove', handleResize);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  return (
    <div
      ref={ref}
      style={{
        position: 'absolute',
        top: position.y,
        left: position.x,
        width: size.width,
        height: size.height,
        border: '0.15rem solid #228B22',
        zIndex: 9999,
        borderRadius: '5px',
      }}
      onMouseDown={handleMouseDown}
    >
        <div style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'space-between',
            width: '100%',
            height: '100%'
        }}>
        {children}

        </div>
      <div
        style={{
          position: 'absolute',
          width: `${resizeHandleSize}px`,
          height: `${resizeHandleSize}px`,
          bottom: `-${resizeHandleMargin}px`,
          right: `-${resizeHandleMargin}px`,
          cursor: 'nwse-resize',
        }}
      />
    </div>
  );
};

export default FloatingDiv;
