import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import MatchCardHeader from '../MatchCardHeader';
import './index.css';
import { Alert } from 'react-bootstrap';

export function MatchCardChart({ match, statistics }) {

  return (
    <div className="match-card">
      <MatchCardHeader match={match} />

      {statistics && statistics.historic && (
        <>
          <Alert variant="success">Palpites: <strong>{statistics.current}</strong></Alert>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={statistics.historic} domain={['dataMin', 'dataMax']}>

              <CartesianGrid strokeDasharray="3 3" />
              
              <XAxis 
                  dataKey="date" 
                  tickFormatter={(unixTime) => {
                    let dat = new Date(unixTime).toLocaleDateString().split('/');
                    let hour = new Date(unixTime).toLocaleTimeString().split(':');
                    return `${dat[0]}/${dat[1]} ${hour[0]}:${hour[1]}`
                  }}
                  interval="preserveEnd"
              />

              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="count" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </>
      )}
    </div>
  );
}
