import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import LoadingIndicator from '../../components/Spinner';
import { getMatchUpdateRequest } from './matchRequest';
import api from '../../services/api';
import { toast } from 'react-toastify';

export default function MatchUpdate({ match, active, onClose }) {
  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(match.enabled || false);
  const [team1, setTeam1] = useState(
    match.result ? match.result.split('x')[0] : 0
  );
  const [team2, setTeam2] = useState(
    match.result ? match.result.split('x')[1] : 0
  );
  const [date, setDate] = useState(match.date || '');
  const [hour, setHour] = useState(match.hour || '');
  const [finish, setFinish] = useState(match.finish || false);

  const handleSave = () => {
    const form = {
      match_id: match.match_id,
      enabled,
      team1,
      team2,
      date,
      hour,
      finish,
    };

    const matchUpdateRequest = getMatchUpdateRequest(form);
    setLoading(true);
    api.srvCadastroApi
      .put('match', matchUpdateRequest)
      .then(response => {
        setLoading(false);
        toast.success('Partida atualizada com sucesso');
        onClose();
      })
      .catch(err => {
        const { error, values } = err.response.data;
        if (error && error === 'Invalid form') {
          toast.error(`Formulário inválido: ${values.join(', ')}`);
        }else {
          toast.error(error);
        }
        setLoading(false);
      });
  };

  return (
    <Modal show={active} onHide={onClose}>
      {loading && <LoadingIndicator />}
      <Modal.Header closeButton>
        <Modal.Title>Editar Partida</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="isEnabled">
            <Form.Label>Ativa</Form.Label>
            <Form.Check
              type="switch"
              checked={enabled}
              onChange={event => setEnabled(event.target.checked)}
            />
          </Form.Group>

          <Form.Group controlId="date">
            <Form.Label>Data</Form.Label>
            <Form.Control
              type="string"
              value={date}
              onChange={event => setDate(event.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="time">
            <Form.Label>Hora</Form.Label>
            <Form.Control
              type="time"
              value={hour}
              onChange={event => setHour(event.target.value)}
            />
          </Form.Group>

          <br />

          <Form.Group controlId="finished">
            <Form.Label>Finalizada?</Form.Label>
            <Form.Check
              type="switch"
              checked={finish}
              onChange={event => setFinish(event.target.checked)}
            />
          </Form.Group>

          {finish && (
            <div>
              <h4>Resultado</h4>
              <div style={{ display: 'flex' }}>
                <Form.Group controlId="team1">
                  <Form.Label>{match.team_one_name}</Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    value={team1}
                    onChange={event => setTeam1(event.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="team2">
                  <Form.Label>{match.team_two_name}</Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    value={team2}
                    onChange={event => setTeam2(event.target.value)}
                  />
                </Form.Group>
              </div>
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancelar
        </Button>
        <Button variant="success" onClick={handleSave}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
