import React, { useState, useEffect, useCallback } from 'react';
import { Button, Table, Form, Modal, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../services/api';
import BodyPage from '../../components/Body';

function Parametrizador() {
  const [params, setParams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [currentParam, setCurrentParam] = useState(null);
  const [rangeValue, setRangeValue] = useState(false);
  const [formData, setFormData] = useState({
    key: '',
    value: '',
    description: '',
  });

  const fetchParams = useCallback(async () => {
    try {
      const response = await api.srvCadastroApi.get('/parametrizador');
      setParams(response.data.data);
      setLoading(false);
    } catch (error) {
      toast.error('Erro ao obter parâmetros');
      setLoading(false);
    }
  }, []);

  const handleFormChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormSubmit = async event => {
    event.preventDefault();
    if (currentParam) {
      try {
        await api.srvCadastroApi.put(
          `/parametrizador`,
          formData
        );
        toast.success('Param atualizado com sucesso');
      } catch (error) {
        toast.error('Erro ao atualizar parâmetro');
      }
    } else {
      try {
        await api.srvCadastroApi.post('/parametrizador', formData);
        toast.success('Param criado com sucesso');
      } catch (error) {
        toast.error('Erro ao criar parâmetro');
      }
    }
    fetchParams();
    handleClose();
  };

  const handleDelete = async param => {
      const confirmDelete = window.confirm("ATENÇÃO: Ao remover este parâmetro, o valor default será utilizado.");
      if(confirmDelete) {
          try {
            await api.srvCadastroApi.delete(`/parametrizador/${param.parametrizador_id}`);
            toast.success('Param deletado com sucesso');
            fetchParams();
          } catch (error) {
            toast.error('Erro ao deletar parâmetro');
          }    
      }
  };

  const handleClose = () => {
    setShowModal(false);
    setCurrentParam(null);
    setFormData({
      key: '',
      value: '',
      description: '',
    });
    setRangeValue(false);
  };

  const handleShow = param => {
    setCurrentParam(param);
    setFormData({
      key: param.key,
      value: param.value,
      description: param.description,
    });
    setShowModal(true);
  };

  const handleRangeChange = e => {
    setRangeValue(e.target.checked);
    setFormData({
      ...formData,
      value: 0.5
    });
  };

  useEffect(() => {
    fetchParams();
  }, [fetchParams]);

  return (
    <BodyPage>
      <>
        <Button
          onClick={() => setShowModal(true)}
          variant="success"
          className="mb-3"
        >
          Adicionar Param
        </Button>
        {loading ? (
          'Loading...'
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Key</th>
                <th>Valor</th>
                <th>Descrição</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {params.map(param => (
                <tr key={param.parametrizador_id}>
                  <td>{param.key}</td>
                  <td>{param.value}</td>
                  <td>{param.description}</td>
                  <td>
                    <Button
                      variant="primary"
                      size='sm'
                      onClick={() => handleShow(param)}
                    >
                      Editar
                    </Button>

                    &emsp;
                    
                    <Button
                      variant="danger"
                      size='sm'
                      onClick={() => handleDelete(param)}
                    >
                      Deletar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {currentParam ? 'Editar Param' : 'Adicionar Param'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleFormSubmit}>
              <Form.Group>
                <Form.Label>Key</Form.Label>
                <Form.Control
                  type="text"
                  name="key"
                  value={formData.key}
                  onChange={handleFormChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Use Range for Value"
                  checked={rangeValue}
                  onChange={handleRangeChange}
                />
                <Form.Label>Valor</Form.Label>
                {
                  rangeValue ? (
                    <Form.Control
                    type="number"
                    name="value"
                    min={0}
                    max={1}
                    step={0.1}
                    value={formData.value}
                    onChange={handleFormChange}
                    required
                  />
                  ) : (
                    <Form.Control
                      type="number"
                      name="value"
                      value={formData.value}
                      onChange={handleFormChange}
                      required
                    />
                  )
                }
              </Form.Group>
              <Form.Group>
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  value={formData.description}
                  onChange={handleFormChange}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                {currentParam ? 'Atualizar Param' : 'Adicionar Param'}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    </BodyPage>
  );
}

export default Parametrizador;
