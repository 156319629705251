
  

export function getMatchConfigRequest(form) {
    const matchRequest = {
        match_id_fk: undefined,
        reward: undefined,
        minimum_guess: undefined,
        type_choice_win: undefined,
    };

    const { match_id_fk, reward, minimum_guess, type_choice_win } = form;

    console.log(form)

    matchRequest.match_id_fk = Number(match_id_fk);   
    matchRequest.reward = reward;
    matchRequest.minimum_guess = minimum_guess;
    matchRequest.type_choice_win = type_choice_win;

    return matchRequest;
}