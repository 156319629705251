import React, { useEffect, useCallback, useState } from 'react';

import { Table, Button } from 'react-bootstrap';
import { GiPodiumWinner } from 'react-icons/gi';
import { BsGear } from 'react-icons/bs';
import { GrEdit } from 'react-icons/gr';
import { MdDone } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingIndicator from '../../components/Spinner';
import BodyPage from '../../components/Body';
import MatchUpdate from '../MatchUpdate';
import api from '../../services/api';
import { Menu } from './styles';
import MatchWinners from '../MatchWinners';
import MatchConfig from '../MatchConfig';
import ErrorModal from '../../components/ErrorModal';
import MatchFilters from '../../components/MatchFilters';
import { convertToUSDateFormat } from '../../util/functionsUtil';

export default function MatchAll() {
  const [loading, setLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [appliedFilters, setAppliedFilters] = useState({});

  const [matches, setMatches] = useState(false);
  const [currentMatch, setCurrentMatch] = useState(false);

  const [updateMatch, setUpdateMatch] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [showWinModal, setShowWinModal] = useState(false);
  const [showConfigModal, setShowConfigModal] = useState(false);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const [guessCounts, setGuessCounts] = useState({});

  const [filterFutureMatches, setFilterFutureMatches] = useState(false);

  function showWinners(match_id) {
    filterMatchById(match_id);
    setShowWinModal(true);
  }  

  function filterMatches(matches) {
    let filteredMatches = matches;
  
    // Filtre as partidas com base nos filtros aplicados
    for (const filter in appliedFilters) {
      filteredMatches = filteredMatches.filter(match =>  appliedFilters[filter] !== '' ? match[filter] === appliedFilters[filter] : match[filter] !== appliedFilters[filter]);
    }
  
    // Filtre as partidas com base no termo de pesquisa
    if (searchTerm) {
      filteredMatches = filteredMatches.filter(match =>
        Object.values(match).some(value =>
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    if (filterFutureMatches) {
      const currentDate = new Date();
      filteredMatches = filteredMatches.filter(match => {
        const matchDate = new Date(convertToUSDateFormat(match.date) + ' ' + match.hour);
        console.log(matchDate)
        return matchDate > currentDate;
       /* return (
          (["ACTIVE", "WAITING_CONFIG", "ON_HOLD"].includes(match.status) &&
          matchDate > currentDate)
        );*/
      });
    }  
    console.log(filteredMatches)
    
      

    const sortedMatches = filteredMatches.sort((a, b) => {
      const dateTimeA = new Date(convertToUSDateFormat(a.date) + ' ' + a.hour);
      const dateTimeB = new Date(convertToUSDateFormat(b.date) + ' ' + b.hour);
      
      // Ordenar de forma crescente (da data mais distante para a mais recente)
      return dateTimeA - dateTimeB;
    });
      
    return sortedMatches;
  }

 
  
  

  function filterMatchById(id) {
    if (!id) {
      toast.error(`Id inválido`);
      return;
    }
    if (matches) {
      const match = matches.filter(match => match.match_id === id)[0];
      setCurrentMatch(match);
    }
  }

  const getAllMatches = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.srvCadastroApi.get('match');
      setLoading(false);

      const { err, success } = response.data;

      if (err) {
        toast.error(err);
        return;
      }

      if (success) {
        setMatches(success);
      }
    } catch (error) {
      setLoading(false);
      toast.error('Não foi possível obter o histórico');
    }
  }, []);

  const defineMatchWinners = useCallback(async (match_id) => {
    try {
      setLoading(true);
      const response = await api.jobVencedoresApi.post(`match-winner/${match_id}`);
      setLoading(false);

      const { err, success } = response.data;

      if (err) {        
        if(err.messages) {
          setErrorMessages(err.messages);
          setShowErrorModal(true); 
          return;
        }      
      }

      if (success) {
        toast.success(success);
        getAllMatches();
      }
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao definir vencedores');
    }
  }, [getAllMatches]) ;

  const fetchGuessCount = async (match_id) => {
    const response = await api.jobVencedoresApi.get(`statistics/guess-match/${match_id}`); 
    const { success } = response.data;
    if(success) {
        setGuessCounts(prevGuessCounts => ({
            ...prevGuessCounts,
            [match_id]: success
        }));
    }
}

  const handleInitUpdate = match => {
    setUpdateMatch(match);
    setIsUpdating(true);
  };

  const handleInitConfig = match => {
    setCurrentMatch(match);
    setShowConfigModal(true);

  }

  const handleCloseModal = () => {
    setIsUpdating(false);
    setUpdateMatch(false);
    setShowWinModal(false);
    setShowConfigModal(false);
    getAllMatches();
  };

  useEffect(() => {
    if (!matches) {
      getAllMatches();
    }
  }, [getAllMatches, matches]);

  return (
    <BodyPage>
      <caption>
        <h3>Partidas</h3>
      </caption>
      <Menu>
        <Link to="/partidas/criar">
          <Button variant="success">Cadastrar Partida</Button>
        </Link>

        &emsp;

        <Link to="/partidas/tipo-configucacao-partida">
          <Button variant="primary">Tipos de Recompensa</Button>
        </Link>
        {loading && <LoadingIndicator />}
      </Menu>

      <MatchFilters
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        appliedFilters={appliedFilters}
        setAppliedFilters={setAppliedFilters}
        filterFutureMatches={filterFutureMatches}
        setFilterFutureMatches={setFilterFutureMatches}
      />

      <Table responsive>        
        <thead>
          <tr>
            <th>ID</th>
            <th>Status</th>
            <th>Campeonato</th>
            <th>Time 1</th>
            <th>Resultado</th>
            <th>Time 2</th>
            <th>Data</th>
            <th>Hora</th>
            <th>Editar</th>
            <th>Config</th>
            <th>Vencedores</th>
            <th>Palpites</th>
          </tr>
        </thead>
        <tbody>
          {matches &&
            filterMatches(matches).map(x => {
              return (
                <tr>
                  <td>{x.match_id}</td>
                  <td>{x.status}</td>
                  <td>{x.championship_nickname}</td>
                  <td>
                    <img 
                      src={x.team_one_logo} 
                      alt={`Escudo do ${x.team_one_name}`} 
                      style={{ width: '30px', height: '30px', marginRight: '10px', verticalAlign: 'middle' }} 
                    />
                    {x.team_one_name}
                  </td>
                  <td>{x.result}</td>
                  <td>
                    <img 
                      src={x.team_two_logo} 
                      alt={`Escudo do ${x.team_two_name}`} 
                      style={{ width: '30px', height: '30px', marginRight: '10px', verticalAlign: 'middle' }} 
                    />
                    {x.team_two_name}
                  </td>
                  <td>{x.date}</td>
                  <td>{x.hour}</td>
                  <td>
                    <Button
                      variant="outline-info"
                      size="sm"
                      onClick={() => handleInitUpdate(x)}
                    >
                      <GrEdit />
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="outline-info"
                      size="sm"
                      onClick={() => handleInitConfig(x)}
                    >
                      <BsGear />
                    </Button>
                  </td>                  
                  <td>
                    {x.winners && x.winners.length > 0 ? (
                      <Button
                        variant="success"
                        size="sm"
                        onClick={() => showWinners(x.match_id)}
                        style={{ background: '#3e8f0c', width: '4rem' }}
                      >
                        <GiPodiumWinner />
                      </Button>
                    ) : (
                      <Button
                        variant="outline-primary" // escolha uma variante diferente de verde, como "outline-primary"
                        size="sm"
                        onClick={() => defineMatchWinners(x.match_id)}
                        style={{ background: '#eee', width: '4rem' }}
                        >
                        <MdDone /> 
                      </Button>
                    )}
                  </td>
                  <td>
                    <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() => fetchGuessCount(x.match_id)}
                    >
                        {guessCounts[x.match_id] || '---'}
                    </Button>
                </td>

                </tr>
              );
            })}
        </tbody>
      </Table>
      <br />

      {isUpdating && <MatchUpdate match={updateMatch} active={isUpdating} onClose={handleCloseModal}/>}

      {showWinModal && <MatchWinners match_id={currentMatch.match_id} winners={currentMatch.winners} active={showWinModal} onClose={handleCloseModal}/>}

      {showConfigModal && <MatchConfig match={currentMatch} active={showConfigModal} onClose={handleCloseModal}/>}

      {showErrorModal && (<ErrorModal show={showErrorModal} errors={errorMessages} handleClose={() => setShowErrorModal(false)}/>)}

      
    </BodyPage>
  );
}
