function formatDate(date) {
    const partesData = date.split('-');
    const dataReversa = partesData.reverse();
    const dataFormatada = dataReversa.join('/');
    return dataFormatada;
  }
  

export function getMatchRequest(form) {
    const matchRequest = {
        championship_id_fk: undefined,
        team_one_id: undefined,
        team_two_id: undefined,
        date: undefined,
        hour: undefined
    };

    const { campeonato, time1, time2, data, hora } = form;

    if(campeonato) {
        matchRequest.championship_id_fk = Number(campeonato);
    } 
    if(time1) {
        matchRequest.team_one_id = Number(time1)
    }
    if(time2) {
        matchRequest.team_two_id = Number(time2)
    }
    if(data) {
        matchRequest.date = formatDate(data)
    }
    if(hora) {
        matchRequest.hour = hora
    }

    return matchRequest;
}