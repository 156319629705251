import React from 'react';
import banner from '../../assets/banner_app.png';
import './Dashboard.css';
import BodyPage from '../../components/Body';

function Dashboard() {
  return (
    <>
      <br />
      <br />
      <br />
      <div
        className="dashboard"
        style={{
          backgroundImage: `url(${banner})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          //minHeight: '60vh',
        }}
      ></div>
    </>
  );
}

export default Dashboard;
