import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  InputGroup,
  FormControl,
  Form,
} from 'react-bootstrap';

import api from '../../services/api'; // Importe a instância da API que você configurou
import { toast } from 'react-toastify';
import { brazilianStates } from '../../util/functionsUtil';
import BodyPage from '../../components/Body';

function Teams() {
  const [teams, setTeams] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [newLogoURL, setNewLogoURL] = useState('');

  const [showStateModal, setShowStateModal] = useState(false);
  const [newHomeState, setNewHomeState] = useState('');

  const fetchData = useCallback(async () => {
    try {
      const responseTeams = await api.srvCadastroApi.get('teams');
      const responseAssets = await api.bffAssetsApi.get('asset/teams');

      if (responseTeams.data.success && responseAssets.data.success) {
        console.log(responseTeams.data);
        const mergedData = responseTeams.data.data.map((team) => {
          const asset = responseAssets.data.data.find(
            (item) => item.team_id === team.team_id,
          );

          return { ...team, logo_svg_base64: asset?.team_logo_sgv_base64 };
        });

        console.log(mergedData);
        setTeams(mergedData);
      }
    } catch (error) {
      console.error('Erro ao buscar dados: ', error);
    }
  }, []);

  const makePngFromTeamURL = useCallback(async (team_id) => {
    try {      
      const response = await api.bffAssetsApi.get(`asset/team/${team_id}`);

      if (response.data.success) {
        toast.success('Sucesso');
      } else {
        toast.error('Erro ao gerar PNG!')
      }
    } catch (error) {
      toast.error('Erro ao gerar PNG')
      console.error('Erro ao buscar dados: ', error);
    }
  }, []);

  useEffect(() => {
    fetchData();

    fetchData();
  }, [fetchData]);



  const renderImage = (team) => {
    const svgStyle = {
      width: "64px",
      maxWidth: "64px",
      background: team.logo_svg_base64 ? "#d9f2e3" : "#f8d7da",
    };
  
    if (team.logo_svg_base64) {
      return (
        <div
          style={svgStyle}
          //dangerouslySetInnerHTML={renderSVG(team.logo_svg_base64)}
          className="team-logo mb-3"
        >
          <img
            src={'data:image/png;base64,' + team.logo_svg_base64}
            alt={team.team_name}
            style={{ width: "100%" }}
          />
        </div>
      );
    } else {
      const isSvg = team.logo_full.endsWith('.svg');
      return (
        <div style={svgStyle} className="team-logo mb-3">
          <img
            src={team.logo_full}
            alt={team.team_name}
            style={{ width: "100%" }}
          />
          <span className="image-type-label">{isSvg ? 'SVG' : 'PNG'}</span>
        </div>
      );
    }
  };
  
  const sortedTeams = teams.slice().sort((a, b) => {
    if (a.home_state && !b.home_state) {
      return -1;
    }
    if (!a.home_state && b.home_state) {
      return 1;
    }
    return a.team_id - b.team_id;
  });  

  const handleHomeStateUpdate = async () => {
    try {
      await api.srvCadastroApi.put('team', {
        team_id: selectedTeam.team_id,
        home_state: newHomeState.toLowerCase(),
      });

      setTeams(
        teams.map((team) =>
          team.team_id === selectedTeam.team_id
            ? { ...team, home_state: newHomeState }
            : team,
        ),
      );

      setSelectedTeam(null);
      setNewHomeState('');
    } catch (error) {
      console.error('Erro ao atualizar o status do time: ', error);
    }
  };

  const handleLogoUpdate = async () => {
    try {
      await api.bffAssetsApi.put('asset/team', {
        team_id: selectedTeam.team_id,
        logo_url: newLogoURL,
      });

      // Atualize a lista de times com a nova URL do escudo
      setTeams(
        teams.map((team) =>
          team.team_id === selectedTeam.team_id
            ? { ...team, logo_svg_base64: newLogoURL }
            : team,
        ),
      );

      // Feche o modal e limpe os estados relacionados
      setShowModal(false);
      setSelectedTeam(null);
      setNewLogoURL('');
    } catch (error) {
      console.error('Erro ao atualizar o escudo do time: ', error);
    }
  };

  /**
   * Função para habilitar ou desabilitar time pelo checkbox
   * @param {boolean} status 
   * @returns 
   */
  const handleUpdateTeamStatus = async (team_id, status) => {
    if(typeof status !== 'boolean' || !Number(team_id)) {
      toast.error('Status ou ID inválido');
      return;
    }
    try {
      await api.srvCadastroApi.put('team', {
        team_id: team_id,
        enabled: status,
      });

      setTeams(
        teams.map((team) =>
          team.team_id === team_id
            ? { ...team, enabled: status }
            : team,
        ),
      );

      setShowStateModal(false);
      setSelectedTeam(null);
      setNewHomeState('');
    } catch (error) {
      console.error('Erro ao atualizar o estado do time: ', error);
    }
  }; 

  return (
    <BodyPage>
      <h2 className="mt-4">Gestão de Times</h2>
      <br />
      <br />
      <Container>
        <Row>
          {sortedTeams.map((team) => (
            <Col key={team.team_id} md={3} className="mb-3">
              <Card>
                <Card.Body>
                  <Card.Title>{`| ${team.home_state ? team.home_state.toUpperCase() + ' / ' +  team.team_name: ' -- / '+ team.team_name} |`}</Card.Title>
                  {renderImage(team)}
                  <Row>                 
                    
                    {team.logo_svg_base64 ? (
                      <Button
                      variant="primary"
                      size="sm"
                      onClick={() => {
                        setSelectedTeam(team);
                        setShowModal(true);
                      }}
                    >
                      Editar 
                    </Button>
                    ) : (
                      <Button
                      variant="success"
                      size="sm"
                      onClick={() => {
                        makePngFromTeamURL(team.team_id)
                      }}
                      >
                        Gerar PNG
                      </Button>
                     
                  
                    )}
                    
                    <Button
                        variant="info"
                        size="sm"
                        className="ml-2"
                        onClick={() => {
                        setSelectedTeam(team);
                        setShowStateModal(true);
                        }}
                      >
                        Atualizar Estado
                      </Button> 

                      <Form.Check 
                        type="checkbox"
                        label="Habilitado"
                        checked={team.enabled} 
                        onChange={() => handleUpdateTeamStatus(team.team_id, !team.enabled)}
                      />
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Editar Escudo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>URL</InputGroup.Text>
                </InputGroup.Prepend>
              <FormControl
                placeholder="Insira a URL do escudo em PNG ou SVG"
                value={newLogoURL}
                onChange={(e) => setNewLogoURL(e.target.value)}
              />
            </InputGroup>
            {newLogoURL && (
              <div className="preview-logo mt-3">
                <img src={newLogoURL} alt="Visualização do escudo" />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleLogoUpdate}>
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showStateModal} onHide={() => setShowStateModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Atualizar Estado</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Label>Selecione o Estado</Form.Label>
                <Form.Control
                  as="select"
                  value={newHomeState}
                  onChange={(e) => setNewHomeState(e.target.value)}
                >
                  <option value="">Selecione...</option>
                  {brazilianStates.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowStateModal(false)}>
                Cancelar
              </Button>
              <Button variant="primary" onClick={handleHomeStateUpdate}>
                Atualizar Estado
              </Button>
            </Modal.Footer>
          </Modal>

      </Container>
    </BodyPage>
  );
}

export default Teams;

