import React from 'react';
import { Container, Row, Col, Button, Jumbotron } from 'react-bootstrap';
import logo from '../../assets/logo.jpeg';
import './Public.css';

function Public() {
  return (
    <div className="home">
      <Jumbotron className="bg-transparent text-center">
        <Container>
          <Row>
            <Col>
              <img src={logo} alt="Bola na Rede" className="logo" />
              <h1 className="display-3">Bola na Rede - Administrativo</h1>
              <p className="lead">
                Área exclusiva para administradores do aplicativo.
              </p>
              <p>
                <Button variant="warning" size="lg" href="/login">
                  Acessar
                </Button>
              </p>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    </div>
  );
}

export default Public;
