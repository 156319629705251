import React, { useState, useEffect, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import BodyPage from '../../components/Body';
import api from '../../services/api';
import { getMatchRequest } from './matchRequest';

export default function MatchCreate() {
  const [campeonatos, setCampeonatos] = useState([]);
  const [allTeams, setAllTeams] = useState([]);
  const [times1, setTimes1] = useState([]);
  const [times2, setTimes2] = useState([]);
  const [formData, setFormData] = useState({
    campeonato: '',
    time1: '',
    time2: '',
    data: '',
    hora: '',
  });

  const time1Ref = useRef('');
  const time2Ref = useRef('');

  useEffect(() => {
    api.srvCadastroApi
      .get('championship/all/actives')
      .then(response => setCampeonatos(response.data.success))
      .catch(error => console.log(error));

    api.srvCadastroApi
      .get('teams')
      .then(response => {
        setAllTeams(response.data.data);
        setTimes1(response.data.data);
        setTimes2(response.data.data);
      })
      .catch(error => console.log(error));
  }, []);

  const handleCampeonatoChange = event => {
    const campeonato = event.target.value;
    setFormData(prevFormData => ({ ...prevFormData, campeonato }));
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  };

  const handleFilterChange = (event, setState, baseRef, targetSearch) => {
    const query = event.target.value;
    const filteredValues = baseRef.filter(item =>
      item[targetSearch].toLowerCase().includes(query.toLowerCase())
    );
    setState(filteredValues);
  };

  const handleSubmit = event => {
    event.preventDefault();
    const matchRequest = getMatchRequest(formData);
    api.srvCadastroApi
      .post('match', matchRequest)
      .then(() => toast.success('Cadastro realizado com sucesso!'))
      .catch(err => {
        const { error, values } = err.response.data;
        if (error && error === 'Invalid form') {
          toast.error(`Formulário inválido: ${values.join(', ')}`);
        }
      });
  };

  return (
    <BodyPage>
      <h3>Cadastrar Partida</h3>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formCampeonato">
          <Form.Label>Campeonato</Form.Label>
          <Form.Control
            as="select"
            onChange={handleCampeonatoChange}
            value={formData.campeonato}
          >
            <option value="">Selecione um campeonato</option>
            {campeonatos.map(campeonato => (
              <option
                key={campeonato.championship_id}
                value={campeonato.championship_id}
              >
                {campeonato.championship_name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formTime1">
          <Form.Label>Time 1</Form.Label>
          <div
            style={{
              display: 'flex',
            }}
          >
            <Form.Control
              as="select"
              onChange={handleInputChange}
              value={formData.time1}
              name="time1"
            >
              <option value="">Selecione um time</option>
              {times1.map(time => (
                <option key={time.team_id} value={time.team_id}>
                  {time.team_name}
                </option>
              ))}
            </Form.Control>
            <Form.Control
              type="text"
              placeholder="Pesquisar time"
              ref={time1Ref}
              onChange={e =>
                handleFilterChange(e, setTimes1, allTeams, 'team_name')
              }
            />
          </div>
        </Form.Group>

        <Form.Group controlId="formTime2">
          <Form.Label>Time 2</Form.Label>
          <div
            style={{
              display: 'flex',
            }}
          >
            <Form.Control
              as="select"
              onChange={handleInputChange}
              value={formData.time2}
              name="time2"
            >
              <option value="">Selecione um time</option>
              {times2.map(time => (
                <option key={time.team_id} value={time.team_id}>
                  {time.team_name}
                </option>
              ))}
            </Form.Control>
            <Form.Control
              type="text"
              placeholder="Pesquisar time"
              ref={time2Ref}
              onChange={e =>
                handleFilterChange(e, setTimes2, allTeams, 'team_name')
              }
            />
            
          </div>
        </Form.Group>

        <Form.Group controlId="formData">
          <Form.Label>Data</Form.Label>
          <Form.Control
            type="date"
            placeholder="Insira a data"
            onChange={handleInputChange}
            value={formData.data}
            name="data"
          />
        </Form.Group>

        <Form.Group controlId="formHora">
          <Form.Label>Hora</Form.Label>
          <Form.Control
            type="time"
            placeholder="Insira a hora"
            onChange={handleInputChange}
            value={formData.hora}
            name="hora"
          />
        </Form.Group>

        <Button variant="success" type="submit">
          Confirmar
        </Button>
      </Form>
    </BodyPage>
  );
}
