import React, { useState } from 'react';
import { Button, Modal, Form, FormControl, InputGroup, ListGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../services/api';

function SendUserNotificationModal() {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    body: '',
    deviceId: '',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleFormChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormSubmit = async event => {
    event.preventDefault();
    if (!selectedUser || !selectedUser.firebase_id) {
      toast.error('Selecione um usuário com um ID Firebase');
      return;
    }
    try {
      await api.srvCadastroApi.post('/notification/sendToOneUser', {
        ...formData,
        deviceId: selectedUser.firebase_id,
      });
      toast.success('Notificação enviada com sucesso');
    } catch (error) {
      toast.error('Falha ao enviar notificação');
    }
    handleClose();
  };

  const handleClose = () => {
    setShowModal(false);
    setFormData({
      title: '',
      body: '',
      deviceId: '',
    });
    setSelectedUser(null);
    setUsers([]);
  };

  const handleShow = () => {
    setShowModal(true);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    fetchUsers();
  }

  const fetchUsers = async () => {
    try {
      const response = await api.srvCadastroApi.get(
        `/users/search?searchTerm=${searchTerm}`
      );
      const { success } = response.data;
      if (success) setUsers(success);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  return (
    <>
      <Button variant="info" onClick={handleShow} className="mb-3">
        Enviar Notificação para um usuário
      </Button>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enviar Notificação para Usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Pesquisar usuário..."
              onChange={handleSearchChange}
            />
          </InputGroup>
          <ListGroup style={{ maxHeight: '200px', overflow: 'auto' }}>
            {users.map(user => (
              user.firebase_id && <ListGroup.Item key={user.user_id} active={user === selectedUser} onClick={() => setSelectedUser(user)}>
                {user.user_name} {user.user_last_name}
              </ListGroup.Item>
            ))}
          </ListGroup>
          <Form onSubmit={handleFormSubmit} className="mt-3">
            <Form.Group>
              <Form.Label>Título</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={formData.title}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Conteúdo</Form.Label>
              <Form.Control
                type="text"
                name="body"
                value={formData.body}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Enviar Notificação
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SendUserNotificationModal;
