import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FiHome, FiLogIn } from 'react-icons/fi';
import { AiOutlineUserAdd } from 'react-icons/ai';

import { logoff } from '../../store/modules/auth/actions';
import { Header } from './styles';

export function HeaderComponentUnsigned() {
  return (
    <Header>
      <nav>
        <Link to="/">
          <FiHome />
          Inicio
        </Link>

        <span>|</span>

        <Link to="/login">
          <FiLogIn />
          Login
        </Link>

        <span>|</span>

        <Link to="/cadastro">
          <AiOutlineUserAdd />
          Cadastrar
        </Link>
      </nav>
    </Header>
  );
}

export function HeaderComponentSigned() {
  const dispatch = useDispatch();
  return (
    <Header>
      <nav>
        <Link to="/inicio">Inicio</Link>

        <span>|</span>

        <Link to="/campeonatos">Campeonatos</Link>

        <span>|</span>

        <Link to="/partidas">Partidas</Link>

        <span>|</span>

        <Link to="/partidas/vencedores">Vencedores</Link>

        <span>|</span>

        <Link to="/partidas/graficos">Gráficos</Link>

        <span>|</span>

        <Link to="/times">Times</Link>

        <span>|</span>

        <Link to="/reporters">Reporters</Link>

        <span>|</span>

        <Link to="/reviews">Reviews</Link>
        
        <span>|</span>

        <Link to="/notifications">Notificações</Link>

        <span>|</span>

        <Link to="/usuarios">Usuários</Link>

        <span>|</span>

        <Link to="/parametrizador">Parametrizador</Link>

        <span>|</span>

        <Link to="/logs">Logs</Link>

        <span>|</span>
        <button
          type="button"
          style={{ background: 'none', border: 'none' }}
          onClick={() => {
            dispatch(logoff());
            this.location.reload();
          }}
        >
          <a href="/login">Sair</a>
        </button>
      </nav>
    </Header>
  );
}
