
  

export function getMatchUpdateRequest(form) {
    const matchRequest = {
        match_id: undefined,
        enabled: undefined,
        finish: undefined,
        result: undefined,
        date: undefined,
        hour: undefined
    };

    const { match_id, enabled, finish, team1, team2, date, hour } = form;

    console.log(form)

    matchRequest.match_id = Number(match_id);   
    matchRequest.enabled = enabled;
    matchRequest.finish = finish;
   
    if(finish) {
        matchRequest.result = `${Number(team1)}x${Number(team2)}`;
    }
    if(date) {
        matchRequest.date = date;
    }
    if(hour) {
        matchRequest.hour = hour;
    }
    return matchRequest;
}