import React, { useState, useEffect, useCallback } from 'react';
import {
  Card,
  Button,
  Form,
  Modal,
  Image,
  DropdownButton,
  Dropdown,
  Table,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../services/api';
import BodyPage from '../../components/Body';
import {
  TO_PAY,
  PENDENCIES,
  WILL_NOT_PAT,
  PAID,
  getPaymentWinStatusDescription,
} from './paymentWinnerStatusEnum';
import './index.css';
import MatchCardHeader from '../../components/MatchCardHeader';

function WinnersManagement() {
  const [winnersData, setWinnersData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedWinner, setSelectedWinner] = useState(null);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(TO_PAY);
  const [notes, setNotes] = useState('');

  const fetchWinners = useCallback(async () => {
    try {
      const monthYear = `${selectedDate.getMonth() +
        1}/${selectedDate.getFullYear()}`;
      const response = await api.srvCadastroApi.post('/winners/mensal', {
        monthYear,
      });
      setWinnersData(response.data.success);
    } catch (error) {
      toast.error('Erro ao buscar vencedores');
    }
  }, [selectedDate]);

  useEffect(() => {
    fetchWinners();
  }, [selectedDate]);

  const handleUpdatePaymentStatus = async () => {
    try {
      const response = await api.srvCadastroApi.put(
        `/winners/payment-status/${selectedWinner.winners_id}`,
        {
          payment_status: paymentStatus,
          notes,
        }
      );
      toast.success('Vencedor atualizado com sucesso');
      setSelectedWinner(null);
      setSelectedMatch(null);
      setPaymentStatus(TO_PAY);
      setNotes('');

      fetchWinners();
    } catch (error) {
      toast.error('Erro ao atualizar o status do pagamento');
    }
  };

  const getPastMonths = () => {
    const months = [];
    const today = new Date();

    for (let i = 0; i <= 12; i++) {
      const d = new Date(today);
      d.setMonth(today.getMonth() - i);
      months.push(d);
    }

    return months;
  };

  return (
    <BodyPage>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <h2>Vencedores</h2>
        <DropdownButton
            id="dropdown-basic-button"            
            title={`Mês Selecionado: ${selectedDate.getMonth() +
            1}/${selectedDate.getFullYear()}`}
        >
            {getPastMonths().map((month, index) => (
            <Dropdown.Item key={index} onClick={() => setSelectedDate(month)}>
                {`${month.getMonth() + 1}/${month.getFullYear()}`}
            </Dropdown.Item>
            ))}
        </DropdownButton>
        </div>

      <br />
      {winnersData.map((match, index) => (
        <Card key={match.match_id} className={`mb-3 ${index % 2 === 0 ? 'alternate-bg' : ''}`}>
          <MatchCardHeader match={match} />
          <Card.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>UF</th>
                  <th>Nome</th>
                  <th>Sobrenome</th>
                  <th>Email</th>
                  <th>Pix</th>
                  <th>Celular</th>
                  <th>CPF</th>
                  <th>Prêmio</th>
                  <th>Status</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {match.winners.map(winner => (
                  <tr key={winner.winners_id}>
                    <td>{winner.user.uf}</td>
                    <td>{winner.user.user_name}</td>
                    <td>{winner.user.user_last_name}</td>
                    <td>{winner.user.email}</td>
                    <td>{winner.user.pix_key}</td>
                    <td>{winner.user.cellphone}</td>
                    <td>{winner.user.cpf}</td>
                    <td>R$ {winner.reward}</td> 
                    <td>
                      {getPaymentWinStatusDescription(winner.payment_status)}
                    </td>
                    <td>
                      <Button
                        variant="success"
                        size="sm"
                        onClick={() => {
                          setNotes(winner.notes);
                          setPaymentStatus(winner.payment_status)
                          setSelectedMatch(match);
                          setSelectedWinner(winner);
                        }}
                      >
                        ✍🏼
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      ))}

      <Modal
        show={selectedWinner !== null}
        onHide={() => {
          setSelectedMatch(null);
          setSelectedWinner(null);
        }}
      >
        <Modal.Header closeButton>
          {selectedMatch && (
            <MatchCardHeader match={selectedMatch} />
          )}
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Status do Pagamento</Form.Label>
              <Form.Control
                as="select"
                value={paymentStatus}
                onChange={e => setPaymentStatus(Number(e.target.value))}
              >
                <option value={TO_PAY}>
                  {getPaymentWinStatusDescription(TO_PAY)}
                </option>
                <option value={PENDENCIES}>
                  {getPaymentWinStatusDescription(PENDENCIES)}
                </option>
                <option value={WILL_NOT_PAT}>
                  {getPaymentWinStatusDescription(WILL_NOT_PAT)}
                </option>
                <option value={PAID}>
                  {getPaymentWinStatusDescription(PAID)}
                </option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Notas</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={notes}
                onChange={e => setNotes(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" onClick={handleUpdatePaymentStatus}>
              Atualizar
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </BodyPage>
  );
}

export default WinnersManagement;
