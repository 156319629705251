import React, { useState } from 'react';

export default function MatchFilters({ searchTerm, setSearchTerm, appliedFilters, setAppliedFilters, filterFutureMatches, setFilterFutureMatches }) {

  return (
    <>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Pesquisar"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
        <div className="input-group-append">
          <button className="btn btn-outline-secondary" type="button">Buscar</button>
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-4">
          <label htmlFor="statusFilter">Status:</label>
          <select
            id="statusFilter"
            className="form-control"
            value={appliedFilters.status || ''}
            onChange={e => {
              setAppliedFilters({ ...appliedFilters, status: e.target.value })
            }}
          >
            <option value="">Todos</option>
            <option value="ACTIVE">Ativa</option>
            <option value="FINISHED">Encerrada</option>
            <option value="WAITING_CONFIG">Aguardando configuração</option>
            <option value="ON_HOLD">Só falta liberar</option>
          </select>
        </div>

        <div className="form-group col-md-4">
          <label htmlFor="categoryFilter">Campeonato:</label>
          <select
            id="categoryFilter"
            className="form-control"
            value={appliedFilters.championship_nickname || ''}
            onChange={e => setAppliedFilters({ ...appliedFilters, championship_nickname: e.target.value })}
          >
            <option value="">Todos</option>
            <option value="Brasileirão">Brasileirão</option>
            <option value="Copa do Brasil">Copa do Brasil</option>
            {/* Adicione as opções de categoria aqui */}
          </select>
        </div>

        <div className="form-group col-md-4">
          <label htmlFor="dateFilter">Data:</label>
          <input
            type="date"
            id="dateFilter"
            className="form-control"
            value={appliedFilters.date || ''}
            onChange={e => setAppliedFilters({ ...appliedFilters, date: e.target.value })}
          />
        </div>

        <div className="form-group col-md-4">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="futureMatchesCheck"
            checked={filterFutureMatches}
            onChange={() => setFilterFutureMatches(prev => !prev)}
          />
          <label className="form-check-label" htmlFor="futureMatchesCheck">
            Mostrar apenas partidas futuras
          </label>
        </div>
      </div>

      </div>
      {/* Outros filtros podem ser adicionados aqui */}
    </>
  );
}
