import React from 'react';
import { Spinner } from 'react-bootstrap';
import './styles.css';


function LoadingIndicator() {
    return (
        <div className="spinner-container">
          <div className="content">
            <Spinner animation="border" role="status" className="spinner">
              <span className="sr-only">Carregando...</span>
            </Spinner>
          </div>
        </div>
      );
}

export default LoadingIndicator;
