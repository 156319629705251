import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { signInSuccess, signFailure } from './actions';

import api from '../../../services/api';
import history from '../../../services/history';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;

    const response = yield call(api.srvCadastroApi.post, 'sessions', { email, password });

    const { success, err } = response.data;    

    if (err) {
      toast.error(err);
      yield put(signFailure());
      return;
    }
    if (success) {
      const { token, tokenBff } = success;

      for(let server of Object.keys(api)) {
        api[server].defaults.headers.Authorization = `Bearer ${token}`;
      }      

      if (tokenBff) {
        for(let server of Object.keys(api)) {
          console.log(server)
          if(server.startsWith('bff')) {
            api[server].defaults.headers.Authorization = `Bearer ${tokenBff}`;
          }
        }  
      }

      yield put(signInSuccess(token, tokenBff, email));

      history.push('/inicio');
      return;
    }

    toast.error('Erro ao fazer login');
    yield put(signFailure());
  } catch (error) {
    toast.error('Erro ao fazer login');
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token, tokenBff } = payload.auth;

  if (token) {
    for(let server of Object.keys(api)) {
      api[server].defaults.headers.Authorization = `Bearer ${token}`;
    }  
  }

  if (tokenBff) {
    for(let server of Object.keys(api)) {
      console.log(server)
      if(server.startsWith('bff')) {
        api[server].defaults.headers.Authorization = `Bearer ${tokenBff}`;
      }
    }  
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),

  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
]);
