import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../services/api';
import LoadingIndicator from '../../components/Spinner';
import BodyPage from '../../components/Body';

export default function TypeMatchConfig() {
  const [loading, setLoading] = useState(false);
  const [typeMatchConfigs, setTypeMatchConfigs] = useState([]);
  const [currentTypeMatchConfig, setCurrentTypeMatchConfig] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [formState, setFormState] = useState({});

  const fetchTypeMatchConfigs = async () => {
    setLoading(true);
    try {
      const response = await api.srvCadastroApi.get('/type-match-config');
      setTypeMatchConfigs(response.data.success);
      setLoading(false);
    } catch (error) {
      toast.error('Erro ao buscar dados');
      setLoading(false);
    }
  };

  const handleShowModal = typeMatchConfig => {
    setCurrentTypeMatchConfig(typeMatchConfig);
    setFormState(typeMatchConfig);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentTypeMatchConfig(null);
    setFormState({});
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (currentTypeMatchConfig) {
      await api.srvCadastroApi.put(
        `/type-match-config/${currentTypeMatchConfig.type_match_config_id}`,
        formState
      );
    } else {
      await api.srvCadastroApi.post('/type-match-config', formState);
    }
    handleCloseModal();
    fetchTypeMatchConfigs();
  };

  const handleDelete = async id => {
    await api.srvCadastroApi.delete(`/type-match-config/${id}`);
    fetchTypeMatchConfigs();
  };

  useEffect(() => {
    fetchTypeMatchConfigs();
  }, []);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <BodyPage>
      <Button variant="primary" onClick={() => handleShowModal(null)}>
        Novo
      </Button>

      &emsp;

      <Button
        variant="info"
        onClick={() =>
          window.open('https://pictogrammers.com/library/mdi/', '_blank')
        }
      >
        Icones
      </Button>
      
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Forma de Vencer</th>
            <th>Recompensa (R$)</th>
            <th>Mínimo de Palpites</th>
            <th>Icone</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {typeMatchConfigs.map(config => (
            <tr key={config.type_match_config_id}>
              <td>{config.type_choice_win}</td>
              <td>{config.default_reward}</td>
              <td>{config.default_min_guess}</td>
              <td>{config.icon}</td>
              <td>
                <Button
                  variant="warning"
                  onClick={() => handleShowModal(config)}
                >
                  Editar
                </Button>

                &emsp;

                <Button
                  variant="danger"
                  onClick={() => handleDelete(config.type_match_config_id)}
                >
                  Deletar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {currentTypeMatchConfig ? 'Edit' : 'Add'} Forma de Vencer
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Forma de Vencer</Form.Label>
              <Form.Control
                type="text"
                value={
                  formState && formState.type_choice_win
                    ? formState.type_choice_win
                    : ''
                }
                onChange={e =>
                  setFormState({
                    ...formState,
                    type_choice_win: e.target.value,
                  })
                }
                required
              />
            </Form.Group>
            <Form.Group> 
              <Form.Label>Recompensa (R$)</Form.Label>
              <Form.Control
                type="number"
                value={formState && formState.default_reward ? formState.default_reward : ''}
                onChange={e => setFormState({ ...formState, default_reward: parseInt(e.target.value, 10) })}
                required
              />
            </Form.Group>
            <Form.Group> 
              <Form.Label>Mínimo de Palpites</Form.Label>
              <Form.Control
                type="number"
                value={formState && formState.default_min_guess ? formState.default_min_guess : ''}
                onChange={e => setFormState({ ...formState, default_min_guess: parseInt(e.target.value, 10) })}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Icone</Form.Label>
              <Form.Control
                type="text"
                value={formState && formState.icon ? formState.icon : ''}
                onChange={e =>
                  setFormState({ ...formState, icon: e.target.value })
                }
                required
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Fechar
            </Button>
            <Button variant="primary" type="submit">
              Salvar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </BodyPage>
  );
}
