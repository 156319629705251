import axios from 'axios';

const DOMAIN = 'bolanarede.app';

function mountUrl(service, port) {
  const env = process.env.REACT_APP_AMBIENTE;

  switch(env) {
    case 'tu':
      return `https://${env}.${service}.${DOMAIN}`;
    case 'prod':
      return `https://${env}.${service}.${DOMAIN}`;  
    case 'local':
      return `http://localhost:${port}`;          
    default:
      throw new Error('Ambiente não definido')
  }
}
const bffPerfilUrl = mountUrl('bff-perfil', 3900);
const bffPartidasUrl = mountUrl('bff-partidas', 4100);
const bffPalpitesUrl = mountUrl('bff-palpites', 4200);
const bffAssetsUrl = mountUrl('bff-assets', 4600);

const srvCadastroUrl = mountUrl('srv-cadastro', 3700);
const srvConectorUrl = mountUrl('srv-conector', 4300);
const srvScrapingUrl = mountUrl('srv-scraping', 4700);

const jobCargaUrl = mountUrl('job-carga', 3800);
const jobVencedoresUrl = mountUrl('job-vencedores', 4400);

const srvDevopsUrl = mountUrl('srv-devops', 4500);
// Apenas produção que tem um srv-devops em cada server
const srvDevopsSrvUrl = mountUrl('srv-devops-srv', 4500);
const srvDevopsBffUrl = mountUrl('srv-devops-bff', 4500);


const srvCadastroApi = axios.create({
  baseURL: srvCadastroUrl,
});

const jobCargaApi = axios.create({
  baseURL: jobCargaUrl,
});
const bffPerfilApi = axios.create({
  baseURL: bffPerfilUrl,
});
const bffPartidasApi = axios.create({
  baseURL: bffPartidasUrl,
});
const bffPalpitesApi = axios.create({
  baseURL: bffPalpitesUrl,
});
const srvConectorApi = axios.create({
  baseURL: srvConectorUrl,
});
const jobVencedoresApi = axios.create({
  baseURL: jobVencedoresUrl,
});
const srvDevopsApi = axios.create({
  baseURL: srvDevopsUrl,
});
const bffAssetsApi = axios.create({
  baseURL: bffAssetsUrl,
});
const srvScrapingApi = axios.create({
  baseURL: srvScrapingUrl,
});

const srvDevopsSrvApi = axios.create({
  baseURL: srvDevopsSrvUrl,
});

const srvDevopsBffApi = axios.create({
  baseURL: srvDevopsBffUrl,
});

const apis = {   
  bffPerfilApi,
  bffPartidasApi,
  bffPalpitesApi,
  bffAssetsApi,

  srvCadastroApi, 
  srvConectorApi,
  srvScrapingApi,
  jobCargaApi,
  jobVencedoresApi,
  
  srvDevopsApi,
  srvDevopsSrvApi,
  srvDevopsBffApi,
};

if(process.env.REACT_APP_AMBIENTE === 'prod') {
  delete apis.srvDevopsApi;
} else {
  delete apis.srvDevopsSrvApi;
  delete apis.srvDevopsBffApi;
}

export default apis;